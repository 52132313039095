export default {
  dataIterator: {
    rowsPerPageText: 'Items per page:',
    rowsPerPageAll: 'All',
    pageText: '{0}-{1} of {2}',
    noResultsText: 'No matching records found',
    nextPage: 'Next page',
    prevPage: 'Previous page'
  },
  dataTable: {
    rowsPerPageText: 'Rows per page:'
  },
  noDataText: 'No data available',
  menu_altera_senha: 'Change Password',
  menu_caixa: 'Wallet',
  menu_clientes: 'Clients',
  menu_condominios: 'Condomínios',
  menu_depositos: 'Deposits',
  menu_despesa: 'Expenses',
  menu_empresa_clientes: 'Clients Company',
  menu_estoque: 'Stock',
  menu_eventos: 'Events',
  menu_formapagamento: 'Form of Payment',
  menu_fornecedores: 'Suppliers',
  menu_histmovcai: 'Cash Handling History',
  menu_histmovest: 'Stock Moviment History',
  menu_itens: 'Items',
  menu_pessoas: 'Peoples',
  menu_reclamacoes: 'Reclamations',
  menu_status_evento: 'Event Status',
  menu_tipos_evento: 'Event Type',
  menu_users: 'Users',
  padrao_exemplo: 'Sample',
  padrao_novo: 'New',
  padrao_salvar: 'Save',
  padrao_salvar_continuar: 'Save and Continue',
  padrao_cancelar: 'Cancel',
  padrao_delete: 'Remove',
  padrao_acoes: 'Actions',
  padrao_ativo: 'Enabled',
  padrao_inativo: 'Disabled',
  padrao_atencao: 'Atention',
  padrao_mais_informacoes: 'More...',
  padrao_campo_obrigatorio: 'Required Field',
  padrao_carregando: 'Loading..',
  padrao_esqueceu_sua_senha: 'Forgot your password?',
  padrao_sucesso: 'Success',
  padrao_deseja_remover_registro: 'Do you want to remove this record?',
  padrao_registro_removido_sucesso: 'Record successfully removed',
  padrao_registro_salvo_sucesso: 'Save Successful',
  padrao_registro_atualizado_sucesso: 'Update Successful',
  padrao_ocorreu_um_erro_ultima_operacao: 'An error ocurred at last operation',
  padrao_ola: 'Hello',
  padrao_registro_nao_encontrado: 'Register not found',
  padrao_digite_para_buscar: 'Type some word to search ... ',
  padrao_pesquisar: 'Search...',
  padrao_sim: 'Yes',
  padrao_nao: 'No',
  padrao_erro_na_aplicacao: 'We are facing some problems',
  padrao_exibir_filtro: 'Show Filter',
  padrao_esconder_filtro: 'Hide Filter',
  padrao_selecione: 'Choose',
  padrao_todos: 'All',
  padrao_janeiro: 'January',
  padrao_fevereiro: 'Febrary',
  padrao_marco: 'March',
  padrao_abril: 'April',
  padrao_maio: 'May',
  padrao_junho: 'June',
  padrao_julho: 'July',
  padrao_agosto: 'August',
  padrao_setembro: 'September',
  padrao_outubro: 'October',
  padrao_novembro: 'November',
  padrao_dezembro: 'December',
  padrao_ano: 'Year',
  padrao_mes: 'Month',
  padrao_alerta: 'Alert',
  padrao_dados_gerais: 'General Data',
  padrao_imagens: 'Images',
  padrao_mostra_imagens: 'Show Images',
  padrao_esconde_imagens: 'Hide Images',
  padrao_gerar_pdf: 'Generate PDF',
  padrao_nao_foi_possivel_gerar_pdf: 'Cannot generate PDF',
  padrao_gerar: 'Generate',
  padrao_data_criacao: 'Created At'
}
