<template>
  <v-menu
    :close-on-content-click="false"
    bottom
    left
    min-width="300"
    max-width="300"
    nudge-left="12"
    offset-x
    transition="slide-y-transition"
  />
</template>

<script>
// Utilities
  import {
    mapMutations,
    mapState
  } from 'vuex'

  export default {
    data: () => ({
      colors: [
        'primary',
        'info',
        'success',
        'warning',
        'danger'
      ],
      images: [
        'https://demos.creative-tim.com/vue-material-dashboard/img/sidebar-1.23832d31.jpg',
        'https://demos.creative-tim.com/vue-material-dashboard/img/sidebar-2.32103624.jpg',
        'https://demos.creative-tim.com/vue-material-dashboard/img/sidebar-3.3a54f533.jpg',
        'https://demos.creative-tim.com/vue-material-dashboard/img/sidebar-4.3b7e38ed.jpg'
      ]
    }),

    computed: {
      ...mapState('app', ['image', 'color']),
      color () {
        return this.$store.state.app.color
      }
    },

    methods: {
      ...mapMutations('app', ['setImage']),
      setColor (color) {
        this.$store.state.app.color = color
      }
    }
  }
</script>

<style lang="scss">
.v-avatar,
.v-responsive {
  cursor: pointer;
}
</style>
