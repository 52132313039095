export default {
  getMenu: (translate) => {
    return [
      {
        to: '/dashboard',
        icon: 'mdi-view-dashboard',
        text: 'Dashboard',
        permissoes: ['A', 'C', 'S']
      },
      {
        divider: true,
        permissoes: ['A', 'C', 'S']
      },
      {
        to: '/empresaClientes',
        icon: 'mdi-account-group',
        text: translate.$t('menu_empresa_clientes') || 'Não Deu Empresa Clientes',
        permissoes: ['A']
      },
      {
        to: '/users',
        icon: 'mdi-account-group',
        text: translate.$t('menu_users') || 'Não Deu Users',
        permissoes: ['A']
      },
      {
        divider: true,
        permissoes: ['A']
      },
      {
        to: '/tipoEventos',
        icon: 'mdi-account-key',
        text: translate.$t('menu_tipos_evento') || 'Não Deu Tipo de Eventos',
        permissoes: ['A', 'C']
      },
      {
        to: '/statusEventos',
        icon: 'mdi-account-key',
        text: translate.$t('menu_status_evento') || 'Não Deu Status de Eventos',
        permissoes: ['A', 'C']
      },
      {
        to: '/clientes',
        icon: 'mdi-account-group',
        text: translate.$t('menu_clientes') || 'Não Deu Clientes',
        permissoes: ['A', 'C', 'S']
      },
      {
        to: '/itens',
        icon: 'mdi-archive',
        text: translate.$t('menu_itens') || 'Não Deu Item',
        permissoes: ['A', 'C']
      },
      {
        to: '/eventos',
        icon: 'mdi-calendar-clock',
        text: translate.$t('menu_eventos') || 'Não Deu Eventos',
        permissoes: ['A', 'C', 'S']
      },
      {
        to: '/textos-contrato',
        icon: 'mdi-format-text',
        text: translate.$t('menu_textos_contrato') || 'Não deu Textos de Contrato',
        permissoes: ['A', 'C']
      },
      {
        to: '/contrato',
        icon: 'mdi-script-text',
        text: translate.$t('menu_contrato') || 'Não deu Contrato',
        permissoes: ['A', 'C']
      },
      {
        divider: true,
        permissoes: ['A', 'C', 'S']
      },
      {
        to: '/depositos',
        icon: 'mdi-calendar-clock',
        text: translate.$t('menu_depositos') || 'Não Deu Depositos',
        permissoes: ['A', 'C', 'S']
      },
      {
        to: '/estoque',
        icon: 'mdi-calendar-clock',
        text: translate.$t('menu_estoque') || 'Não Deu estoque',
        permissoes: ['A', 'C', 'S']
      },
      {
        to: '/histmovest',
        icon: 'mdi-calendar-clock',
        text: translate.$t('menu_histmovest') || 'Não Deu Histórico Movimentação de Estoque',
        permissoes: ['A', 'C', 'S']
      },
      {
        divider: true,
        permissoes: ['A', 'C', 'S']
      },
      {
        to: '/fornecedores',
        icon: 'mdi-account-group',
        text: translate.$t('menu_fornecedores') || 'Não Deu Fornecedores',
        permissoes: ['A', 'C']
      },
      {
        to: '/despesas',
        icon: 'mdi-account-key',
        text: translate.$t('menu_despesa') || 'Não Deu Despesas',
        permissoes: ['A', 'C']
      },
      {
        divider: true,
        permissoes: ['Z']
      },
      {
        to: '/caixas',
        icon: 'mdi-cash-usd',
        text: translate.$t('menu_caixa') || 'Não Deu Caixa',
        permissoes: ['Z']
      },
      {
        to: '/formasPagamento',
        icon: 'mdi-cash-usd',
        text: translate.$t('menu_formapagamento') || 'Não Deu Forma Pagamento',
        permissoes: ['Z']
      },
      {
        to: '/histmovcai',
        icon: 'mdi-cash-usd',
        text: translate.$t('menu_histmovcai') || 'Não deu Historico Movimentacao Caixa',
        permissoes: ['Z']
      },
      {
        divider: true,
        permissoes: ['A', 'C']
      },
      {
        to: '/resetPassword',
        icon: 'mdi-lock-question',
        text: translate.$t('menu_altera_senha') || 'Não Deu Altera Senha',
        permissoes: ['A', 'C', 'S']
      }
    ]
  }
}
