<template>
  <v-footer
    id="core-footer"
    absolute
    height="60"
    color="primary"
    dark
  >
    <div class="footer-items">
      <span
        v-for="link in links"
        :key="link.name"
      >
        <a
          :href="link.Link"
          class="white--text footer-links"
        >{{ link.name }}</a>
      </span>
    </div>
    <v-spacer />
    <span class="white--text font-weight-light copyright">
      &copy;
      {{ (new Date()).getFullYear() }}
      <a
        class="white--text"
        href="http://controle-eventos.nawebb.com"
        target="_blank"
      >Controle Eventos</a>
      <!-- <v-icon
        color="tertiary"
        size="30"
      >mdi-domain</v-icon>-->
    </span>
    <span>
      <img
        src="/favicon-192x192.png"
        height="50"
        contain
      >
    </span>
  </v-footer>
</template>

<script>
  export default {
    data: () => ({
      links: [{ name: 'Home', Link: '/dashboard' }]
    })
  }
</script>

<style>
#core-footer {
  z-index: 0;
}
</style>
