<template>
  <div
    v-if="item && item.codigo"
    :style="{display:displayDiv}"
  >
    <div
      :style="'background-color:' + item.codigo"
      class="quadrado-cor"
    />
    <span>{{ item.cor }}</span>
  </div>
</template>

<script>
  export default {
    props: {
      item: {
        type: Object,
        default: null
      },

      displayDiv: {
        type: String,
        default: 'contents'
      }
    },
    watch: {
      item (val) {
      }
    }
  }
</script>

<style>
.quadrado-cor {
  height: 15px;
  width: 15px;
  padding: 5px;
  margin-right: 10px;
}
</style>
