import Api from '@/services/Api'

export default {
  buscaEstados () {
    return Api().get('https://servicodados.ibge.gov.br/api/v1/localidades/estados')
  },
  buscaCidades (idEstado) {
    return Api().get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${idEstado}/municipios`)
  },
  buscaEnderecoPorCEP (cep) {
    // return Api().get('https://viacep.com.br/ws/' + cep + '/json/')
    return Api().get('buscaEnderecoPorCEP/' + cep + '')
  },
  buscaCepPorUfCidadeRua (estado, municipio, rua) {
    return Api().get(`https://viacep.com.br/ws/${estado}/${municipio}/${rua}/json/`)
  },
  buscaCidadesPorParam (param) {
    return Api().get(`cidadesPorParam?param=${param}`)
  }
}
