<template>
  <v-icon
    :small="small"
    :color="ativo ? 'green' : 'red'"
  >
    {{ ativo ? 'mdi-check-circle' : 'mdi-close-circle' }}
  </v-icon>
</template>
<script>
  export default {
    props: {
      ativo: {
        type: Boolean,
        default: true
      },
      small: {
        type: Boolean,
        default: true
      }
    }
  }
</script>