<template>
  <v-data-table
    v-if="mostraTabela"
    :headers="headers"
    :items="items"
    :options.sync="paginacao"
    :server-items-length="totalRegistros"
    :loading="loading"
    :hide-default-header="hideHeaders"
    :hide-default-footer="hideActions"
    :footer-props="{
      prevIcon:'mdi-menu-left color-black',
      nextIcon:'mdi-menu-right color-black'
    }"
  >
    <!-- 
    footer-props.prev-icon="mdi-menu-left tertiary"
    footer-props.next-icon="mdi-menu-right tertiary" -->
    <template slot="no-data">
      <v-btn
        color="primary"
        @click.stop="novo()"
      >
        {{ $t('padrao_novo') }}
      </v-btn>
    </template>
    <template
      v-if="items && items.length"
      slot="top"
    >
      <v-row>
        <slot name="antesDoBotaoAdicionar" />
        <v-col 
          v-if="$vuetify.breakpoint.smAndDown"
          cols="12"
        >
          <v-btn
            color="accent"
            class="mb-4"
            @click.stop="novo()"
          >
            <v-icon>mdi-plus</v-icon>
            {{ $t('padrao_novo') }}
          </v-btn>
        </v-col>
        <v-spacer v-if="$vuetify.breakpoint.mdAndUp" />
        <v-btn
          v-if="$vuetify.breakpoint.mdAndUp"
          color="accent"
          class="mb-4"
          @click.stop="novo()"
        >
          <v-icon>mdi-plus</v-icon>
          {{ $t('padrao_novo') }}
        </v-btn>
        
        <slot name="depoisDoBotaoAdicionar" />
      </v-row>
      <hr>
    </template>
    <template
      slot="headerCell"
      slot-scope="{ header }"
    >
      <span
        class="subheading font-weight-light text-success text--darken-3"
        v-text="header.text"
      />
    </template>
    <slot
      slot="headers"
      name="headers"
    />
    <slot
      slot="top"
      name="top"
    />
    <slot
      slot="item"
      slot-scope="item"
      :item="item"
      name="items"
    />
  </v-data-table>
</template>

<script>
  export default {
    props: {
      mostraTabela: {
        type: Boolean,
        default: false
      },
      headers: {
        type: Array,
        default: null
      },
      items: {
        type: Array,
        default: null
      },
      totalRegistros: {
        type: Number,
        default: 0
      },
      loading: {
        type: Boolean,
        default: false
      },
      hideHeaders: {
        type: Boolean,
        default: false
      },
      hideActions: {
        type: Boolean,
        default: false
      }
    },
    data: () => ({
      pagination: null
    }),
    computed: {
      paginacao: {
        get () {
          return this.pagination
        },
        set (paginacao) {
          if (this.pagination !== paginacao) {
            this.$emit('update:paginacao', paginacao)
            this.pagination = paginacao
            this.buscar()
          }
        }
      }
    },
    watch: {
    },
    methods: {
      buscar () {
        this.$emit('buscar-event', {
          pagination: this.pagination
        })
      },
      novo () {
        this.$emit('novo-event')
      }
    }
  }
</script>

<style>


.color-black {
  color: black !important;
}

</style>
