<template>
  <v-app>
    <!-- <core-filter /> -->

    <core-toolbar
      v-if="$route.name !== 'Login' && $route.name !== 'Altera Senha'"
    />

    <core-drawer
      v-if="$route.name !== 'Login' && $route.name !== 'Altera Senha'"
    />

    <core-view />
    <dialogo-ok />
    <dialogo-loading />
  </v-app>
</template>
<script>
  import ImagemService from '@/services/ImagemService'
  import AuthenticationService from './services/AuthenticationService'
  import { USER_KEY } from './config/config'
  export default {
    name: 'App',
    data () {
      return {
        validatingToken: false
      }
    },
    created () {
      this.$root.$on('view-image', ImagemService.imgClick)
      this.validateToken()
    },

    methods: {
      async validateToken () {
        this.$store.commit('setLoading', true)

        const json = localStorage.getItem(USER_KEY)
        const userData = JSON.parse(json)

        if (!userData) {
          this.$store.commit('setUser', null)
          this.$store.commit('setIdEmpresaCliente', null)
          this.$store.commit('setLoading', false)
          this.$router.push('/login')
          return
        }

        const res = await AuthenticationService.validateToken(userData)

        if (res.data) {
          this.$store.commit('setIdEmpresaCliente', userData.idEmpresaCliente)
          this.$store.commit('setUser', userData)
        } else {
          this.$store.commit('setUser', null)
          this.$store.commit('setIdEmpresaCliente', null)
          localStorage.removeItem(USER_KEY)
          this.$router.push('/login')
        }

        this.$store.commit('setLoading', false)
      }
    }
  }
</script>

<style lang="scss">
@import "@/styles/index.scss";

/* Remove in 1.2 */
.v-datatable thead th.column.sortable i {
  vertical-align: unset;
}
</style>
