import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import 'vuetify/dist/vuetify.min.css'
import '../styles/arshome.css'
import pt from 'vuetify/es5/locale/pt'
import en from 'vuetify/es5/locale/en'
import lang from '@/lang'
import theme from './theme'

Vue.use(Vuetify)

export default new Vuetify({
  icons: {
    iconfont: 'mdi' // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4'
  },
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: theme
    }
  },
  lang: {
    locales: { en: { ...lang.en, ...en }, pt: { ...lang.pt, ...pt }, pt_BR: { ...lang.pt, ...pt } },
    current: 'pt'
  }
})
