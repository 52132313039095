import Vue from 'vue'
import DateUtils from '../utils/DateUtils'
import VueMask from 'v-mask'

Vue.directive('money', // {
  function (elemento, binding) {
    // const valor = parseFloat(elemento.innerHTML)
    const valor = parseFloat(binding.value.valor)
      .toFixed(2)
      .replace('.', ',')
      // eslint-disable-next-line no-useless-escape
      .replace(/(\d)(?=(\d{3})+\,)/g, '$1.')
    elemento.innerHTML = `${binding.value.simbolo} ${valor}`
    // }
  })

Vue.directive('data-formatada', {
  inserted (elemento, binding) {
    let minhaData = elemento.innerHTML.toString()
    try {
      minhaData = DateUtils.formatDate(minhaData)
    } catch (error) {
      minhaData = ''
    }
    elemento.innerHTML = minhaData
  }
}
)

Vue.use(VueMask)