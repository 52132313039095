<template>
  <v-select
    :value="value"
    :label="$t('CaixaForm.nome')"
    :items="caixas"
    item-value="id"
    item-text="nome"
    :required="required"
    prepend-icon="mdi-cash-usd"
    @input="disparaEvento($event)"
    @click:clear="disparaEventoLimpaCampo($event)"
  />
</template>

<script>

  import CaixaService from '../services/CaixaService'

  export default {
    props: {
      required: {
        type: Boolean,
        default: false
      },
      value: {
        type: Number,
        default: null
      },
      idEmpresaCliente: {
        type: Number,
        default: null
      },
      adicionaValorTodos: {
        type: Boolean,
        default: false
      },
      adicionaValorSelecione: {
        type: Boolean,
        default: false
      }
    },
    data: () => ({
      caixas: []
    }),
    watch: {
      idEmpresaCliente(val){
        this.buscaItens()
      }
    },
    mounted()  {
      this.buscaItens()
    },
    methods: {
      buscaItens() {
        if(this.idEmpresaCliente && this.idEmpresaCliente > 0){
          const param = {
            idEmpresaCliente: this.idEmpresaCliente,
            nome: '%'
          }
          const pagination = {
            itemsPerPage: -1
          }
          CaixaService.findByParam(param, pagination)
            .then(caixas => {
              this.caixas = caixas.data.rows
              if(this.adicionaValorTodos){
                this.caixas.unshift({
                  id: null, 
                  nome: this.$i18n.t('padrao_todos')
                })
              }
              if(this.adicionaValorSelecione){
                this.caixas.unshift({
                  id: null, 
                  nome: this.$i18n.t('padrao_selecione')
                })
              }
            })
        }else{
          this.caixas = []
        }
      },
      disparaEventoLimpaCampo (event) {
        this.$emit('click:clear', event)
      },
      disparaEvento (event) {
        this.$emit('input', event)
      },
    }
  }
</script>