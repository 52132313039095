import axios from 'axios'
import { BASE_URL } from '../config/config'

export default () => {
  const baseURL = BASE_URL || 'http://localhost:8081'
  var axiosInstance = axios.create({
    baseURL
  })
  axiosInstance.defaults.headers.post['Access-Control-Allow-Origin'] = '*'
  return axiosInstance
}
