<template>
  <dialogo-form
    :dialog.sync="dialogVisible"
    :loading-dialog="loadingDialog"
    :form-title="formTitle"
    :is-editting="isEditting"
    :dialog-width="'500px'"
    :dialog-fullscreen="$vuetify.breakpoint.smAndDown"
  >
    <span slot="botoes" />

    <crud-filho-form
      slot="campos"
      :mostra-formulario="true"
      :is-editting="isEditting"
      :mensagem-pergunta-remocao="mensagemPerguntaRemocao"
      :alerta-validacao="alertValidacao"
      :mensagem-erro="error"
      :mostra-botao-salvar-continuar="false"
      classe-form="ml-3"
      id-form="imagemForm"
      @closeDialogEvent="closeDialogParent"
      @saveEvent="saveEventHandler"
      @save-continue-event="saveEvent"
      @deleteEvent="remover"
    >
      <v-col
        v-if="imageUrl"
        cols="11"
        style="max-height:200px;"
        class="text-center text-sm-center text-md-center text-lg-center overflowHidden"
      >
        <v-img
          v-if="imageUrl"
          id="componenteImagem"
          ref="img"
          :src="imageUrl"
          contain
          class="cursorPointer"
          @click="()=>{this.$root.$emit('view-image',imageUrl)}"
        />
      </v-col>
      <v-col
        cols="11"
        class="text-center text-sm-center text-md-center text-lg-center"
      >
        <v-text-field
          v-model="imageName"
          label="Selecione uma imagem!"
          prepend-icon="mdi mdi-attachment"
          @click="pickFile"
        />
        <input
          ref="image"
          type="file"
          style="display: none"
          accept="image/*"
          @change="onFilePicked"
        >
      </v-col>
      <v-col
        v-if="mostraTituloComentario"
        cols="11"
      >
        <v-text-field
          v-model="objEditar.titulo"
          prepend-icon="mdi mdi-book-open"
          label="Título"
        />
      </v-col>
      <v-col
        v-if="mostraTituloComentario"
        cols="11"
      >
        <v-textarea
          v-model="objEditar.comentario"
          auto-grow
          prepend-icon="mdi mdi-pencil"
          label="Comentários"
          outlined
        />
      </v-col>
      <v-alert
        v-model="alertValidacao"
        dismissible
        type="error"
        transition="scale-transition"
      >
        {{ error }}
      </v-alert>
    </crud-filho-form>
  </dialogo-form>
</template>

<script>

  import DialogoForm from '@/components/DialogoForm'
  import ImagemService from '@/services/ImagemService'
  import FormData from 'form-data'
  import ImageTools from '@/utils/ImageTools'

  export default {
    components: {
      DialogoForm
    },
    model: {
      prop: 'dialog'
    },
    props: {
      dialog: {
        type: Boolean,
        default: false

      },
      isEditting: {
        type: Boolean,
        default: true
      },
      idImagem: {
        type: Number,
        default: 0
      },
      mostraTituloComentario:{
        type: Boolean,
        default: true
      }
    },
    data () {
      return {
        mensagemPerguntaRemocao: 'Deseja remover esta imagem?',
        loadingDialog: false,
        alertValidacao: false,

        autoCompleteModel: null,
        autoCompleteItens: [],
        autoCompleteLoading: false,
        autoCompleteCampoBusca: '',

        error: null,
        formTitle: 'Enviar Imagem',
        imageName: '',
        imageUrl: '',
        imageFile: '',
        tamanhoImagem: '150px',
        validForm: true,
        objPadrao: {
          titulo: '',
          comentario: '',
          img: null,
          nomeArquivo: ''
        },
        objEditar: {
          titulo: '',
          comentario: '',
          img: null,
          nomeArquivo: ''
        }
      }
    },
    computed: {
      dialogVisible: {
        get: function () {
          return this.dialog
        },
        set: function (val) {
          if (this.dialog !== val) {
            this.$emit('update:dialog', val)
          }
        }
      },
      idImagemComputed: {
        get: function () {
          return this.idImagem
        },
        set: function (val) {
          if (this.idImagem !== val) {
            this.$emit('update:idImagem', val)
          }
        }
      }
    },

    beforeUpdate () {
      if (this.dialog && this.idImagemComputed > 0 && this.objEditar.id === undefined) {
        try {
          this.loadingDialog = true
          ImagemService.findByIds({
            ids: [this.idImagemComputed]
          }).then(imagens => {
            if (imagens && imagens.data && imagens.data.length > 0) {
              this.objEditar = imagens.data[0]
              this.imageName = this.objEditar.nomeArquivo
              const buffer = Buffer.from(this.objEditar.img, 'base64')
              var myBase64 = window.btoa(ImagemService.Uint8ToString(buffer))
              this.imageUrl = 'data:image/*;base64,' + myBase64
            } else {
              this.$root.$emit('mostra_dialogo_erro', {
                dialogoDescricao: 'Não foi encontrada imagem para o identificador informado'
              })
            }
          }).finally(() => {
            this.loadingDialog = false
          })
        } catch (error) {
          this.$root.$emit('mostra_dialogo_erro', {
            dialogoDescricao: 'Ocorreu um erro ao buscar a imagem informads:\n' + error
          })
        } finally {
          // 
        }
      }
    },
    methods: {
      closeDialogParent () {
        this.objEditar = Object.assign({}, this.objPadrao)
        this.idImagemComputed = 0
        this.dialogVisible = false
        this.$refs.image.files = null
        this.imageUrl = null
        this.imageName = ''
        this.objEditar = Object.assign({}, this.objPadrao)
        this.autoCompleteModel = null
        this.autoCompleteItens = []
      },
      pickFile () {
        this.$refs.image.click()
      },
      onFilePicked (e) {
        const files = e.target.files
        if (files[0] !== undefined) {
          this.imageName = files[0].name
          if (this.imageName.lastIndexOf('.') <= 0) {
            return
          }
          const fr = new FileReader()
          fr.readAsDataURL(files[0])
          fr.addEventListener('load', () => {
            // this.imageUrl = fr.result
            this.imageFile = files[0] // this is an image file that can be sent to server...
            this.recortaImagem()
          })
        } else {
          this.imageName = ''
          this.imageFile = ''
          this.imageUrl = ''
        }
      },
      recortaImagem () {
        try {
          new ImageTools().resize(this.imageFile, { width: 1024, height: 1024 })
            .then(blob => {
              if (blob instanceof Blob) {
                const reader = new FileReader()
                reader.addEventListener('load', () => {
                  this.imageUrl = reader.result
                  this.imageFile = blob
                })
                reader.readAsDataURL(blob)
              }
            })
            .catch(error => {
              error = { ...error }
            })
        } catch (error) {
          // 
        }
      },
      saveEventHandler () {
        this.loadingDialog = true
        this.objEditar.img = this.imageFile
        this.objEditar.nomeArquivo = this.imageName
        if (this.idImagemComputed > 0) {
          this.atualizar(this.objEditar)
        } else {
          this.inserir(this.objEditar)
        }
      },
      remover () {
        const imagem = this.objEditar
        try {
          ImagemService.delete(imagem)
            .then(response => {
              this.$root.$emit('mostra_dialogo_alerta', {
                dialogoTitulo: 'Sucesso',
                dialogoDescricao: 'Imagem removida com sucesso'
              })
              this.$emit('imagem-removida', imagem.id)
              this.closeDialogParent()
            })
        } catch (error) {
          this.$root.$emit('mostra_dialogo_erro', {
            dialogoTitulo: 'Erro',
            dialogoDescricao: 'Deu algum erro no catch ->' + error
          })
        }
      },
      atualizar (imagem) {
        try {
          const form = document.getElementById('imagemForm')
          const formData = new FormData(form)
          formData.append('file', imagem.img, {
            filename: imagem.nomeArquivo,
            contentType: 'application/octet-stream',
            mimeType: 'application/octet-stream'
          })
          formData.append('nomeArquivo', imagem.nomeArquivo)
          formData.append('titulo', imagem.titulo)
          formData.append('comentario', imagem.comentario)
          formData.append('id', imagem.id)
          ImagemService.update(formData)
            .then(response => {
              if (!response) {
                this.$root.$emit('mostra_dialogo_erro', {
                  dialogoDescricao: 'deu algum erro, response == null'
                })
              } else if (response.error) {
                this.$root.$emit('mostra_dialogo_erro', {
                  dialogoDescricao: 'deu algum erro, response == ' + response.error
                })
              } else {
                this.$root.$emit('mostra_dialogo_info', {
                  dialogoTitulo: 'Sucesso',
                  dialogoDescricao: 'Imagem salva com sucesso'
                })
                this.objEditar = response.data
                if (this.objEditar.id > 0) {
                  this.$emit('imagem-salva', this.objEditar.id)
                }
                this.closeDialogParent()
              }
            })
        } catch (error) {
          this.$root.$emit('mostra_dialogo_erro', {
            dialogoDescricao: 'Deu algum erro no catch ->' + error
          })
        } finally {
          this.loadingDialog = false
        }
      },
      saveEvent () {
        this.isEditting = false

        this.objEditar.CidadeId = this.autoCompleteModel.id
        this.objEditar.PessoaId = this.idPessoa

        if (this.editedIndex > -1) {
          this.atualizar(this.objEditar)
        } else {
          this.inserir(this.objEditar)
        }
        this.closeDialogParent()
      },
      inserir (imagem) {
        try {
          const form = document.getElementById('imagemForm')
          const formData = new FormData(form)
          formData.append('file', imagem.img, {
            filename: imagem.nomeArquivo,
            contentType: 'application/octet-stream',
            mimeType: 'application/octet-stream'
          })
          formData.append('nomeArquivo', imagem.nomeArquivo)
          formData.append('titulo', imagem.titulo)
          formData.append('comentario', imagem.comentario)
          ImagemService.insert(formData)
            .then(response => {
              if (!response) {
                this.$root.$emit('mostra_dialogo_erro', {
                  dialogoDescricao: 'Deu erro no response'
                })
              } else if (response.error) {
                this.$root.$emit('mostra_dialogo_erro', {
                  dialogoDescricao: 'Deu erro no response ->' + response.error
                })
              } else {
                this.$root.$emit('mostra_dialogo_info', {
                  dialogoTitulo: 'Sucesso',
                  dialogoDescricao: 'Imagem salva com sucesso'
                })
                this.objEditar = response.data
                if (this.objEditar.id > 0) {
                  this.$emit('imagem-salva', this.objEditar.id)
                }
                this.closeDialogParent()
              }
            })
        } catch (error) {
          this.$root.$emit('mostra_dialogo_erro', {
            dialogoDescricao: 'Deu erro no response ->' + error
          })
        } finally {
          this.loadingDialog = false
        }
      }
    }
  }
</script>

<style scope>
.overflowHidden {
  overflow: hidden;
}
</style>
