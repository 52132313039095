import Api from '@/services/Api'

export default {
  insert (caixa) {
    return Api().post('caixa', caixa)
  },
  update (caixa) {
    return Api().put('caixa', caixa)
  },
  delete (caixa) {
    return Api().delete(`caixa?id=${caixa.id}`, caixa)
  },
  findById (caixa) {
    const url = `caixa/${caixa.id}`
    return Api().get(url)
  },
  findByParam (param, pagination) {
    param = {
      param, pagination
    }
    return Api().post('caixasParam', param)
  },
  buscaCaixaPorParam (param, idEmpresaCliente) {
    return Api().get(`caixasPorParam?idEmpresaCliente=${idEmpresaCliente}&param=${param}`)
  }
}
