import Api from '@/services/Api'

export default {
  findByIds (ids, pagination) {
    const param = {
      ...ids, pagination
    }
    return Api().post('imagens', param)
  },
  insert (imagem) {
    return Api().post('imagem', imagem)
  },
  update (imagem) {
    return Api().put('imagem', imagem)
  },
  delete (imagem) {
    return Api().delete(`imagem?id=${imagem.id}`, imagem)
  },
  findByParam (param, pagination) {
    param = {
      ...param, pagination
    }
    return Api().get('condominioParam', param)
  },
  Uint8ToString (u8a) {
    var CHUNK_SZ = 0x8000
    var c = []
    for (var i = 0; i < u8a.length; i += CHUNK_SZ) {
      c.push(String.fromCharCode.apply(null, u8a.subarray(i, i + CHUNK_SZ)))
    }
    return c.join('')
  },
  imgClick (imagem) {
    var image = new Image()
    image.src = imagem
    image.style = 'cursor:pointer'

    var w = window.open('', '_blank')
    w.document.write(image.outerHTML)
    w.document.write('<script>document.querySelector("img").onclick = function () {window.close()}\x3C/script>')
    w.document.close()
  },
  processaImagemBanco(imagemProcessar){
    const buffer = Buffer.from(imagemProcessar.img, 'base64')
    var myBase64 = window.btoa(this.Uint8ToString(buffer))
    imagemProcessar.img = 'data:image/*;base64,' + myBase64
  }
}
