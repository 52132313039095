import Api from '@/services/Api'

export default {
  insert (deposito) {
    return Api().post('formaPagamento', deposito)
  },
  update (deposito) {
    return Api().put('formaPagamento', deposito)
  },
  delete (deposito) {
    return Api().delete(`formaPagagamento?id=${deposito.id}`, deposito)
  },
  findByParam (param, pagination) {
    param = {
      param, pagination
    }
    return Api().post('formaPagamentosParam', param)
  },
  findById (deposito) {
    const url = `formaPagamento/${deposito.id}`
    return Api().get(url)
  },
  buscaFormaPagamentosPorParam (param, idEmpresaCliente) {
    return Api().get(`formaPagamentosPorParam?idEmpresaCliente=${idEmpresaCliente}&param=${param}`)
  }

}
