<template>
  <v-dialog
    v-model="dialog"
    width="400px"
  >
    <v-card>
      <v-card-title
        class="secondary py-2 title "
        style="color:#004d40;height:45px;"
      >
        {{ dialogoTitulo }}
      </v-card-title>
      <v-card-text>
        <v-icon
          v-if="type === 'question'"
          color="primary"
        >
          mdi mdi-help-circle
        </v-icon>
        <v-icon
          v-else-if="type === 'alert'"
          color="warning"
        >
          mdi mdi-alert
        </v-icon>
        <v-icon
          v-else-if="type === 'info'"
          color="info"
        >
          mdi mdi-comment-text-multiple
        </v-icon>
        <v-icon
          v-else
          color="error"
        >
          mdi mdi-image-broken-variant
        </v-icon>
        <span id="spanDescricao">{{ dialogoDescricao }}</span>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          v-if="mostraBotaoOk"
          :class="btnOkClass"
          @click.stop="disparaEventoBotaoSim"
        >
          <v-icon class="pr-2">
            mdi mdi-alert-circle
          </v-icon>
          OK
        </v-btn>
        <v-btn
          v-if="mostraBotoesSimNao"
          :class="btnSimClass"
          @click="disparaEventoBotaoSim"
        >
          <v-icon class="pr-2">
            mdi mdi-thumb-up
          </v-icon>
          Sim
        </v-btn>
        <v-btn
          v-if="mostraBotoesSimNao"
          :class="btnNaoClass"
          @click.stop="dialog = false"
        >
          <v-icon class="pr-2">
            mdi mdi-thumb-down
          </v-icon>
          Não
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    data () {
      return {
        dialog: false,
        dialogoTitulo: 'Erro na aplicação',
        dialogoDescricao: 'Teste de Descrição',
        mostraBotoesSimNao: false,
        mostraBotaoOk: true,
        btnOkClass: 'error',
        btnSimClass: 'success',
        btnNaoClass: 'error',
        type: 'error',
        funcaoRetorno: null
      }
    },
    created () {
      this.$root._events.mostra_dialogo_erro = []
      this.$root._events.mostra_dialogo_info = []
      this.$root._events.mostra_dialogo_alerta = []
      this.$root._events.mostra_dialogo_pergunta = []
      this.$root.$on('mostra_dialogo_erro', this.mostraDialogoErro)
      this.$root.$on('mostra_dialogo_info', this.mostraDialogoInfo)
      this.$root.$on('mostra_dialogo_alerta', this.mostraDialogoAlerta)
      this.$root.$on('mostra_dialogo_pergunta', this.mostraDialogoPergunta)
    },
    methods: {
      mostraDialogoErro (dialogo) {
        if (!dialogo) {
          return
        }

        this.dialogoTitulo = dialogo.dialogoTitulo || 'Erro na aplicação'
        this.dialogoDescricao = dialogo.dialogoDescricao || 'Ocorreu um erro na aplicação'
        this.mostraBotoesSimNao = dialogo.mostraBotoesSimNao || false
        this.type = dialogo.type || 'error'
        this.mostraBotaoOk = dialogo.mostraBotaoOk || true
        this.btnOkClass = dialogo.btnOkClass || 'error'
        this.btnSimClass = dialogo.btnSimClass || 'success'
        this.btnNaoClass = dialogo.btnNaoClass || 'error'
        this.funcaoRetorno = dialogo.funcaoRetorno
        this.dialog = true
      },
      mostraDialogoAlerta (dialogo) {
        if (!dialogo) {
          return
        }

        this.dialogoTitulo = dialogo.dialogoTitulo || 'Alerta'
        this.dialogoDescricao = dialogo.dialogoDescricao || 'Ocorreu um erro na aplicação'
        this.type = dialogo.type || 'alert'
        this.mostraBotoesSimNao = dialogo.mostraBotoesSimNao || false
        this.mostraBotaoOk = dialogo.mostraBotaoOk || true
        this.btnOkClass = dialogo.btnOkClass || 'warning'
        this.btnSimClass = dialogo.btnSimClass || 'success'
        this.btnNaoClass = dialogo.btnNaoClass || 'error'
        this.funcaoRetorno = dialogo.funcaoRetorno

        this.dialog = true
      },
      mostraDialogoInfo (dialogo) {
        if (!dialogo) {
          return
        }

        this.dialogoTitulo = dialogo.dialogoTitulo || 'Informação'
        this.dialogoDescricao = dialogo.dialogoDescricao || 'Ocorreu um erro na aplicação'
        this.mostraBotoesSimNao = dialogo.mostraBotoesSimNao || false
        this.type = dialogo.type || 'info'
        this.mostraBotaoOk = dialogo.mostraBotaoOk || true
        this.btnOkClass = dialogo.btnOkClass || 'info'
        this.btnSimClass = dialogo.btnSimClass || 'success'
        this.btnNaoClass = dialogo.btnNaoClass || 'error'
        this.funcaoRetorno = dialogo.funcaoRetorno

        this.dialog = true
      },
      mostraDialogoPergunta (dialogo) {
        if (!dialogo) {
          return
        }

        this.dialogoTitulo = dialogo.dialogoTitulo || 'Dúvida'
        this.dialogoDescricao = dialogo.dialogoDescricao || 'Ocorreu um erro na aplicação'
        this.mostraBotoesSimNao = dialogo.mostraBotoesSimNao || true
        this.type = dialogo.type || 'question'
        this.mostraBotaoOk = dialogo.mostraBotaoOk || false
        this.btnOkClass = dialogo.btnOkClass || 'info'
        this.btnSimClass = dialogo.btnSimClass || 'success'
        this.btnNaoClass = dialogo.btnNaoClass || 'error'
        this.funcaoRetorno = dialogo.funcaoRetorno

        this.dialog = true
      },
      disparaEventoBotaoSim () {
        this.dialog = false
        if (this.funcaoRetorno) {
          this.funcaoRetorno()
        }
      }
    }
  }
</script>

<style>
#spanDescricao {
  font-size: 1.3em;
  padding-left: 10px;
}
</style>
