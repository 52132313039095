<template>
  <v-dialog
    v-model="dialogFlag"
    :width="dialogWidth"
    :class="dialogClass"
    :fullscreen="dialogFullscreen"
  >
    <v-card>
      <slot name="cabecalho">
        <v-toolbar
          id="toolbar-header"
          color="primary"
          class="ml-0"
        >
          <v-toolbar-title>
            <span 
              class="subheading font-weight-bold mr-3 white--text"
              style="align-self: left;"
            >{{ formTitle }}
            </span>
          </v-toolbar-title>
          <v-spacer />
          <v-btn
            icon
            @click="closeDialog()"
          >
            <v-icon>mdi mdi-close</v-icon>
          </v-btn>
          <slot
            slot="extension"
            name="extensao_cabecalho"
          />
        </v-toolbar>
      </slot>
      <v-progress-linear
        :indeterminate="true"
        :active="loadingDialog"
        color="info"
        height="3"
        class="mt-0"
      />
      <slot name="campos" />
      <slot name="aposCampos" />
      <slot name="botoes">
        <v-card-actions>
          <slot name="botoesAdicionaisTelaGrandeEsquerdaDeletar" />
          <v-btn
            v-if="isEditting"
            color="error"
            class="hidden-sm-and-down"
            @click="deleteEvent()"
          >
            delete
            <v-icon dark>
              delete
            </v-icon>
          </v-btn>
          <!-- <v-btn flat color="primary">More</v-btn> -->
          <slot name="botoesAdicionaisTelaGrandeDireitaDeletar" />
          <v-spacer />
          <slot name="botoesAdicionaisTelaGrandeEsquerdaCancelar" />
          <v-btn
            class="hidden-sm-and-down"
            @click="closeDialog()"
          >
            Cancelar
            <v-icon dark>
              mdi mdi-close
            </v-icon>
          </v-btn>
          <v-btn
            color="primary"
            class="hidden-sm-and-down"
            @click="saveEvent()"
          >
            Salvar
            <v-icon dark>
              mdi mdi-content-save
            </v-icon>
          </v-btn>
          <v-btn
            v-if="mostraBotaoSalvarContinuar"
            color="info"
            class="hidden-sm-and-down"
            @click="saveContinueEvent()"
          >
            Salvar e Continuar
            <v-icon dark>
              mdi mdi-content-save
            </v-icon>
          </v-btn>
          <slot name="botoesAdicionaisTelaGrandeDireitaSalvar" />
          <v-menu
            offset-y
            class="hidden-md-and-up"
          >
            <v-btn
              slot="activator"
              color="primary"
              dark
            >
              <v-icon dard>
                mdi mdi-gesture-tap
              </v-icon>
              Ações
            </v-btn>
            <v-list>
              <slot name="botoesAdicionaisTelaPequenaAntesSalvar" />
              <v-list-item
                v-if="mostraBotaoSalvarContinuar"
                @click="saveContinueEvent()"
              >
                <v-list-item-action>
                  <v-icon color="info">
                    mdi mdi-content-save
                  </v-icon>
                </v-list-item-action>
                <v-list-item-title>Salvar e Continuar</v-list-item-title>
              </v-list-item>
              <v-list-item @click="saveEvent()">
                <v-list-item-action>
                  <v-icon color="primary">
                    mdi mdi-content-save
                  </v-icon>
                </v-list-item-action>
                <v-list-item-title>Salvar</v-list-item-title>
              </v-list-item>
              <v-list-item @click="closeDialog()">
                <v-list-item-action>
                  <v-icon>mdi mdi-close</v-icon>
                </v-list-item-action>
                <v-list-item-title>Cancelar</v-list-item-title>
              </v-list-item>
              <slot name="botoesAdicionaisTelaPequenaDepoisCancelar" />
              <v-divider v-if="isEditting" />
              <v-divider v-if="isEditting" />
              <slot name="botoesAdicionaisTelaPequenaAntesDeletar" />
              <v-list-item
                v-if="isEditting"
                @click="deleteEvent()"
              >
                <v-list-item-action>
                  <v-icon color="error">
                    delete
                  </v-icon>
                </v-list-item-action>
                <v-list-item-title>Remover</v-list-item-title>
              </v-list-item>
              <slot name="botoesAdicionaisTelaPequenaDepoisDeletar" />
            </v-list>
          </v-menu>
        </v-card-actions>
      </slot>
    </v-card>
  </v-dialog>
</template>
<script>
  export default {
    model: {
      prop: 'dialog'
    },
    props: {
      dialogWidth: {
        type: String,
        default: '800px'
      },
      dialog: {
        type: Boolean,
        default: false
      },
      formTitle: {
        type: String,
        default: ''
      },
      isEditting: {
        type: Boolean,
        default: false
      },
      dialogClass: {
        type: String,
        default: null
      },
      dialogFullscreen: {
        type: Boolean,
        default: false
      },
      loadingDialog: {
        type: Boolean,
        default: false
      },
      mostraBotaoSalvarContinuar: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      dialogFlag: {
        get: function () {
          return this.dialog
        },
        set: function (val) {
          if (this.dialog !== val) {
            this.closeDialog()
          }
        }
      }
    },
    methods: {
      closeDialog () {
        this.$emit('closeDialogEvent')
        this.$emit('update:dialog')
      },
      saveEvent () {
        this.$emit('saveEvent')
      },
      saveContinueEvent () {
        this.$emit('save-continue-event')
      },
      deleteEvent () {
        this.$emit('deleteEvent')
      }
    }
  }
</script>
<style>
#toolbar-header .v-toolbar__content {
    margin-left: 0 !important;
}
</style>
