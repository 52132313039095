import Vue from 'vue'
import Vuex from 'vuex'
import modules from './modules'
import axios from 'axios'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: null,
    idEmpresaCliente: 0,
    admin: false,
    loading: false
  },
  mutations: {
    setLoading (state, loading) {
      state.loading = loading
    },
    setAdmin (state, admin) {
      state.admin = admin
    },
    setIdEmpresaCliente (state, idEmpresaCliente) {
      state.idEmpresaCliente = idEmpresaCliente
    },
    setUser (state, user) {
      state.user = user
      if (user) {
        axios.defaults.headers.common['Authorization'] = `bearer ${user.token}`
      } else {
        delete axios.defaults.headers.common['Authorization']
      }
    }
  },
  actions: {
  },
  modules
})
