<template>
  <v-select
    :value="value"
    :label="$t('FormaPagamentoForm.titulo')"
    :items="registros"
    item-value="id"
    item-text="nome"
    :required="required"
    prepend-icon="mdi-ballot-outline"
    @input="disparaEvento($event)"
    @click:clear="disparaEventoLimpaCampo($event)"
  />
</template>

<script>

  import FormaPagamentoService from '../services/FormaPagamentoService'

  export default {
    props: {
      required: {
        type: Boolean,
        default: false
      },
      value: {
        type: Number,
        default: null
      },
      idEmpresaCliente: {
        type: Number,
        default: null
      },
      adicionaValorTodos: {
        type: Boolean,
        default: false
      },
      adicionaValorSelecione: {
        type: Boolean,
        default: false
      },
      filtraDespesas: {
        type:Boolean,
        default: true
      }
    },
    data: () => ({
      registros: []
    }),
    watch: {
      idEmpresaCliente(val){
        this.buscaItens()
      }
    },
    mounted()  {
      this.buscaItens()
    },
    methods: {
      buscaItens() {
        if(this.idEmpresaCliente && this.idEmpresaCliente > 0){
          const param = {
            idEmpresaCliente: this.idEmpresaCliente
          }
          const pagination = {
            itemsPerPage: -1
          }
          param.tipo = this.filtraDespesas ? 'D' : 'R'
          FormaPagamentoService.findByParam(param, pagination)
            .then(registros => {
              this.registros = registros.data.rows
              if(this.adicionaValorTodos){
                this.registros.unshift({
                  id: null, 
                  nome: this.$i18n.t('padrao_todos')
                })
              }
              if(this.adicionaValorSelecione){
                this.registros.unshift({
                  id: null, 
                  nome: this.$i18n.t('padrao_selecione')
                })
              }
            })
        }else{
          this.registros = []
        }
      },
      disparaEventoLimpaCampo (event) {
        this.$emit('click:clear', event)
      },
      disparaEvento (event) {
        this.$emit('input', event)
      },
    }
  }
</script>