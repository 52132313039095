<template>
  <v-form
    v-if="mostraFormulario"
    :id="idForm"
    :class="classeForm"
    xs12
  >
    <slot />
    <v-alert
      v-model="alertValidacao"
      dismissible
      type="error"
      transition="scale-transition"
    >
      {{ mensagemErro }}
    </v-alert>
    <slot name="modificadoPor" />
    <slot name="botoes">
      <v-col cols="12">
        <v-card-actions>
          <v-btn
            v-if="isEditting && mostraBotaoDeletar"
            color="error"
            class="hidden-sm-and-down"
            @click="deleteEvent()"
          >
            {{ $vuetify.lang.t('$vuetify.padrao_delete') }}
            <v-icon dark>
              mdi-trash-can-outline
            </v-icon>
          </v-btn>
          <v-spacer />
          <slot name="botoesAdicionaisAntesCancelar" />
          <v-btn
            color="tertiary"
            class="hidden-sm-and-down"
            @click="closeDialog()"
          >
            {{ $vuetify.lang.t('$vuetify.padrao_cancelar') }}
            <v-icon>
              mdi mdi-close
            </v-icon>
          </v-btn>
          <slot name="botoesAdicionaisAntesSalvar" />
          <v-btn
            color="primary"
            class="hidden-sm-and-down"
            @click="saveEvent()"
          >
            {{ $vuetify.lang.t('$vuetify.padrao_salvar') }}
            <v-icon dark>
              mdi mdi-content-save
            </v-icon>
          </v-btn>
          <slot name="botoesAdicionaisAntesSalvarContinuar" />
          <v-btn
            v-if="(!isEditting && mostraBotaoSalvarContinuar) || forcaMostraBotaoSalvarContinuar"
            class="hidden-sm-and-down"
            color="success"
            @click="saveContinueEvent()"
          >
            {{ $vuetify.lang.t('$vuetify.padrao_salvar_continuar') }}
            <v-icon dark>
              mdi-content-save
            </v-icon>
          </v-btn>
          <v-menu
            v-if="$vuetify.breakpoint.smAndDown"
            offset-y
            class="hidden-md-and-up"
          >
            <template v-slot:activator="{on}">
              <v-btn
                color="primary"
                dark
                v-on="on"
              >
                <v-icon dard>
                  mdi mdi-gesture-tap
                </v-icon>
                {{ $vuetify.lang.t('$vuetify.padrao_acoes') }}
              </v-btn>
            </template>
            <v-list>
              <slot name="botoesAdicionaisTelaPequenaAntesSalvar" />
              <v-list-item
                v-if="(!isEditting && mostraBotaoSalvarContinuar) || forcaMostraBotaoSalvarContinuar"
                @click="saveContinueEvent()"
              >
                <v-list-item-action>
                  <v-icon color="info">
                    mdi-content-save
                  </v-icon>
                </v-list-item-action>
                <v-list-item-title>{{ $vuetify.lang.t('$vuetify.padrao_salvar_continuar') }}</v-list-item-title>
              </v-list-item>
              <v-list-item @click="saveEvent()">
                <v-list-item-action>
                  <v-icon color="primary">
                    mdi mdi-content-save
                  </v-icon>
                </v-list-item-action>
                <v-list-item-title>{{ $vuetify.lang.t('$vuetify.padrao_salvar') }}</v-list-item-title>
              </v-list-item>
              <v-list-item @click="closeDialog()">
                <v-list-item-action>
                  <v-icon color="tertiary">
                    mdi mdi-close
                  </v-icon>
                </v-list-item-action>
                <v-list-item-title>{{ $vuetify.lang.t('$vuetify.padrao_cancelar') }}</v-list-item-title>
              </v-list-item>

              <slot name="botoesAdicionaisTelaPequenaDepoisCancelar" />
              <v-divider v-if="isEditting" />
              <v-divider v-if="isEditting" />
              <slot name="botoesAdicionaisTelaPequenaAntesDeletar" />
              <v-list-item
                v-if="isEditting && mostraBotaoDeletar"
                @click="deleteEvent()"
              >
                <v-list-item-action>
                  <v-icon color="error">
                    mdi-trash-can-outline
                  </v-icon>
                </v-list-item-action>
                <v-list-item-title>{{ $vuetify.lang.t('$vuetify.padrao_delete') }}</v-list-item-title>
              </v-list-item>
              <slot name="botoesAdicionaisTelaPequenaDepoisDeletar" />
            </v-list>
          </v-menu>
        </v-card-actions>
      </v-col>
    </slot>
  </v-form>
</template>

<script>

  export default {
    props: {
      mostraFormulario: {
        type: Boolean,
        default: false
      },
      isEditting: {
        type: Boolean,
        default: true
      },
      mensagemPerguntaRemocao: {
        type: String,
        default: null
      },
      mensagemErro: {
        type: String,
        default: null
      },
      alertValidacao: {
        type: Boolean,
        default: false
      },
      mostraBotaoSalvarContinuar: {
        type: Boolean,
        default: true
      },
      forcaMostraBotaoSalvarContinuar: {
        type: Boolean,
        default: false
      },
      mostraBotaoDeletar: {
        type: Boolean,
        default: true
      },
      classeForm: {
        type: String,
        default: null
      },
      idForm: {
        type: String,
        default: null
      }
    },
    methods: {
      remover () {
        this.$emit('deleteEvent')
      },
      deleteEvent () {
        this.$root.$emit('mostra_dialogo_pergunta', {
          dialogoDescricao: this.mensagemPerguntaRemocao,
          funcaoRetorno: this.remover
        })
      },

      closeDialog () {
        this.$emit('closeDialogEvent')
      },
      saveEvent () {
        this.$emit('saveEvent')
      },
      saveContinueEvent () {
        this.$emit('save-continue-event')
      }
    }
  }
</script>
