<template>
  <v-toolbar
    id="core-toolbar"
    flat
    style="background: #eee; max-height:60px;"
    class="ma-0"
    :class="{'noDrawer' : !drawer}"
  >
    <v-btn
      v-if="!responsive"
      fab
      dark
      small
      color="primary"
      class="mr-3"
      style="padding:7px;"
      @click.stop="onClickBtn"
    >
      <v-icon>
        {{ iconDrawer }}
      </v-icon>
    </v-btn>
    <v-text-field
      v-if="mostraPesquisa"
      v-model="pesquisa"
      class="mr-4 mt-2"
      :label="$t('padrao_pesquisar')"
      hide-details
      color="purple"
      @keyup.enter="enterPressed"
    />
    <v-spacer />
    <v-spacer v-if="drawer" />
    <v-toolbar-items>
      <v-col
        class="align-center layout"
      >
        <v-btn
          v-if="responsive"
          icon
          @click.stop="onClickBtn"
        >
          <v-icon color="primary">
            mdi-view-list
          </v-icon>
        </v-btn>
        <!--
        <router-link
          v-ripple
          class="toolbar-items"
          to="/"
        >
          <v-icon color="tertiary">
            mdi-view-dashboard
          </v-icon>
        </router-link>
        <v-menu
          v-if="notifications && notifications.length > 0"
          bottom
          left
          content-class="dropdown-menu"
          offset-y
          transition="slide-y-transition"
        >
          <router-link
            v-ripple
            class="toolbar-items"
            to="/notifications"
          >
            <v-badge
              color="error"
              overlap
            >
              <template slot="badge">
                {{ notifications.length }}
              </template>
              <v-icon color="tertiary">
                mdi-bell
              </v-icon>
            </v-badge>
          </router-link>
          <v-card>
            <v-list-item
              v-for="notification in notifications"
              :key="notification"
              @click="onClick"
            >
              <v-list-item-title v-text="notification" />
            </v-list-item>
          </v-card>
        </v-menu>
        <router-link
          v-if=" && $store.state.user"
          v-ripple
          class="toolbar-items"
          to="/user-profile"
        >
          <v-icon color="tertiary">
            mdi-account
          </v-icon>
        </router-link>
        -->
        <router-link
          v-if="!$store.state.user"
          v-ripple
          class="toolbar-items"
          to="/login"
        >
          <v-icon color="tertiary">
            mdi-login-variant
          </v-icon>
        </router-link>
        <span v-if="user && user.nome && !responsive">
          {{ user.nome }}
        </span>
        <v-btn
          v-if="$store.state.user"
          icon
          @click="logout()"
        >
          <v-icon color="tertiary">
            mdi-logout-variant
          </v-icon>
        </v-btn>
        <v-btn
          icon
          @click="changeLanguage()"
        >
          <v-icon color="tertiary">
            mdi-earth
          </v-icon>
        </v-btn>
      </v-col>
    </v-toolbar-items>
  </v-toolbar>
</template>

<script>

  import {
    mapMutations, mapState
  } from 'vuex'

  import { USER_KEY } from '@/config/config'

  export default {
    data: () => ({
      notifications: [],
      title: null,
      responsive: false,
      responsiveInput: false,
      language: 'pt',
      pesquisa: '',
      mostraPesquisa: true
    }),

    computed: {
      ...mapState(['user']),
      ...mapState('app', ['drawer']),

      iconDrawer () {
        if (this.$store.state.app.drawer) {
          return 'mdi-less-than'
        } else {
          return 'mdi-greater-than'
        }
      }
    },

    watch: {
      '$route' (val) {
        this.title = val.name
      }
    },

    created () {
      this.$root.$on('alteraMostraPesquisa', this.alteraMostraPesquisa)
    },

    mounted () {
      this.onResponsiveInverted()
      window.addEventListener('resize', this.onResponsiveInverted)
    },
    beforeDestroy () {
      window.removeEventListener('resize', this.onResponsiveInverted)
    },
    methods: {
      enterPressed () {
        this.$root.$emit('pesquisa', this.pesquisa)
      },
      changeLanguage () {
        if (this.language === 'pt') {
          this.language = 'en'
        } else {
          this.language = 'pt'
        }
        this.$vuetify.lang.current = this.language
        this.$i18n.locale = this.language
        this.$root.$emit('change-language', this.language)
      },
      ...mapMutations('app', ['setDrawer', 'toggleDrawer']),
      onClickBtn () {
        this.setDrawer(!this.$store.state.app.drawer)
      },
      onClick () {
      //
      },
      alteraMostraPesquisa (mostraPesquisa) {
        this.pesquisa = ''
        this.mostraPesquisa = mostraPesquisa
      },
      onResponsiveInverted () {
        if (window.innerWidth < 991) {
          this.responsive = true
          this.responsiveInput = false
        } else {
          this.responsive = false
          this.responsiveInput = true
        }
      },
      logout () {
        localStorage.removeItem(USER_KEY)
        this.$store.commit('setUser', null)
        this.$router.push('/login')
      }
    }

  }
</script>

<style>
#core-toolbar a {
  text-decoration: none;
}

.noDrawer .v-toolbar__content {
  margin-left: 0px !important;
}
</style>
