<template>
  <v-container
    class="fill-height"
    fluid
  >
    <v-row
      justify="center"
    >
      <v-col cols="12">
        <material-card
          id="materialCardTeste"
          color="green"
        >
          <v-col
            v-if="!tabsCabecalho"
            slot="header"
            cols="1"
          >
            <v-btn
              class="v-btn--simple mt-0"
              icon
              @click="closeDialog()"
            >
              <v-icon>mdi-door-open</v-icon>
            </v-btn>
            <span
              class="subheading font-weight-light mr-3"
              style="align-self: center"
            >{{ tituloPagina }}
            </span>
          </v-col>

          <v-col
            v-if="tabsCabecalho"
            id="materialCardHeader"
            slot="header"
          >
            <v-tabs
              v-model="tabSelecionada"
              color="transparent"
              slider-color="yellow"
            >
              <v-btn
                class="v-btn--simple mt-0"
                icon
                @click="closeDialog()"
              >
                <v-icon>mdi-door-open</v-icon>
              </v-btn>
              <span
                class="subheading font-weight-light mr-3"
                style="align-self: center"
              >{{ tituloPaginaTab }}</span>
              <v-tab
                v-for="item in tabsCabecalho"
                :key="item.texto"
                :href="item.link"
                class="mr-3"
              >
                {{ item.texto }}
                <v-icon
                  v-if="item.icone"
                  class="mr-2"
                >
                  {{ item.icone }}
                </v-icon>
              </v-tab>
            </v-tabs>
          </v-col>
          <v-form>
            <slot />
          </v-form>
          <slot name="botoes">
            <template slot="actions">
              <v-btn
                v-if="isEditting"
                color="error"
                class="hidden-sm-and-down"
                @click="deleteEvent()"
              >
                {{ $vuetify.lang.t('$vuetify.padrao_delete') }}
                <v-icon dark>
                  mdi-close
                </v-icon>
              </v-btn>
              <v-spacer />
              <v-btn
                color="tertiary"
                class="hidden-sm-and-down"
                @click="closeDialog()"
              >
                {{ $vuetify.lang.t('$vuetify.padrao_cancelar') }}
                <v-icon dark>
                  mdi-close
                </v-icon>
              </v-btn>
              <v-btn
                class="hidden-sm-and-down"
                color="info"
                @click="saveEvent()"
              >
                {{ $vuetify.lang.t('$vuetify.padrao_salvar') }}
                <v-icon dark>
                  mdi-content-save
                </v-icon>
              </v-btn>
              <v-btn
                v-if="!isEditting"
                class="hidden-sm-and-down"
                color="success"
                @click="saveContinueEvent()"
              >
                {{ $vuetify.lang.t('$vuetify.padrao_salvar_continuar') }}
                <v-icon dark>
                  mdi-content-save
                </v-icon>
              </v-btn>
              <v-menu
                offset-y
                class="hidden-md-and-up"
              >
                <v-btn slot="activator">
                  <v-icon dard>
                    mdi-gesture-tap
                  </v-icon>
                  {{ $vuetify.lang.t('$vuetify.padrao_acoes') }}
                </v-btn>
                <v-list>
                  <slot name="botoesAdicionaisTelaPequenaAntesSalvar" />
                  <v-list-item
                    v-if="mostraBotaoSalvarContinuar"
                    @click="saveContinueEvent()"
                  >
                    <v-list-item-action>
                      <v-icon color="info">
                        mdi-content-save
                      </v-icon>
                    </v-list-item-action>
                    <v-list-item-title>{{ $vuetify.lang.t('$vuetify.padrao_salvar_continuar') }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="saveEvent()">
                    <v-list-item-action>
                      <v-icon color="primary">
                        mdi-content-save
                      </v-icon>
                    </v-list-item-action>
                    <v-list-item-title>{{ $vuetify.lang.t('$vuetify.padrao_salvar') }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="closeDialog()">
                    <v-list-item-action>
                      <v-icon>mdi-close</v-icon>
                    </v-list-item-action>
                    <v-list-item-title>{{ $vuetify.lang.t('$vuetify.padrao_cancelar') }}</v-list-item-title>
                  </v-list-item>
                  <slot name="botoesAdicionaisTelaPequenaDepoisCancelar" />
                  <v-divider v-if="isEditting" />
                  <v-divider v-if="isEditting" />
                  <slot name="botoesAdicionaisTelaPequenaAntesDeletar" />
                  <v-list-item
                    v-if="isEditting"
                    @click="deleteEvent()"
                  >
                    <v-list-item-action>
                      <v-icon color="error">
                        mdi-close
                      </v-icon>
                    </v-list-item-action>
                    <v-list-item-title>{{ $vuetify.lang.t('$vuetify.padrao_delete') }}</v-list-item-title>
                  </v-list-item>
                  <slot name="botoesAdicionaisTelaPequenaDepoisDeletar" />
                </v-list>
              </v-menu>
            </template>
          </slot>
        </material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

  export default {
    props: {
      tituloPaginaTab: {
        type: String,
        default: null
      },
      tituloPagina: {
        type: String,
        default: null
      },
      tabsCabecalho: {
        type: Array,
        default: null
      },
      isEditting: {
        type: Boolean,
        default: true
      },
      mensagemPerguntaRemocao: {
        type: String,
        default: null
      }
    },
    data: () => ({
      tab: null,
      mostraBotaoSalvarContinuar: false
    }),
    computed: {
      tabSelecionada: {
        get () {
          return this.tab
        },
        set (tabSelecionada) {
          if (this.tab !== tabSelecionada) {
            this.$emit('update:tab-selecionada', tabSelecionada)
            this.tab = tabSelecionada
          }
        }
      }
    },
    methods: {
      remover () {
        this.$emit('deleteEvent')
      },
      deleteEvent () {
        this.$root.$emit('mostra_dialogo_pergunta', {
          dialogoDescricao: this.mensagemPerguntaRemocao,
          funcaoRetorno: this.remover
        })
      },

      closeDialog () {
        this.$emit('closeDialogEvent')
      },
      saveEvent () {
        this.$emit('saveEvent')
      },
      saveContinueEvent () {
        this.$emit('save-continue-event')
      }
    }
  }
</script>
