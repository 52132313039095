<template>
  <v-navigation-drawer
    id="app-drawer"
    v-model="inputValue"
    app
    dark
    floating
    persistent
    width="260"
  >
    <!-- mobile-breakpoint="991" -->
    <v-row
      class="height"
    >
      <v-list-item style="max-height: 60px;margin-left:7px">
        <v-list-item-avatar>
          <v-img
            src="/favicon-192x192.png"
            height="60"
            contain
          />
        </v-list-item-avatar>
        <v-list-item-title class="title">
          Controle Eventos
        </v-list-item-title>
      </v-list-item>
      <v-divider />
      <template 
        v-for="(link, i) in links"
      >
        <v-divider
          v-if="link.divider"
          :key="i" 
          class="divider-margin"
        />

        <v-list-item 
          v-else
          :key="i"
          :to="link.to"
          :active-class="color"
          class="v-list-item tamanho-40"
        >
          <!--  -->
          <v-list-item-action>
            <v-icon>{{ link.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-title v-text="link.text" />
        </v-list-item>
      </template>
    </v-row>
  </v-navigation-drawer>
</template>

<script>
// Utilities
  import {
    mapMutations,
    mapState
  } from 'vuex'
  import { USER_KEY } from '../../config/config'
  import menu from '../../utils/menu'

  export default {
    data: () => ({
      pesquisa: '',
      links: null,
      responsive: false,
      mostraPesquisa: true
    }),
    computed: {
      ...mapState('app', ['image', 'color']),
      inputValue: {
        get () {
          return this.$store.state.app.drawer
        },
        set (val) {
          this.setDrawer(val)
        }
      },
      items () {
        return this.$t('Layout.View.items')
      }
    },
    watch: {
      pesquisa () {
        this.enterPressed()
      },
      mostraPesquisa () {
      }
    },
    created () {
      this.fillItems()
      this.$root.$on('change-language', this.fillItems)
      this.$root.$on('alteraMostraPesquisa', this.alteraMostraPesquisa)
    },
    mounted () {
      this.onResponsiveInverted()
      window.addEventListener('resize', this.onResponsiveInverted)
    },
    beforeDestroy () {
      window.removeEventListener('resize', this.onResponsiveInverted)
    },
    methods: {
      enterPressed () {
        this.$root.$emit('pesquisa', this.pesquisa)
      },
      alteraMostraPesquisa (mostraPesquisa) {
        this.mostraPesquisa = mostraPesquisa
      },
      fillItems () {

        const json = localStorage.getItem(USER_KEY)
        const userData = JSON.parse(json)
        const allLinks = menu.getMenu(this)
        this.links = allLinks.filter(e => {
          return e.permissoes.indexOf(userData.permissao) >= 0
        })
      },
      ...mapMutations('app', ['setDrawer', 'toggleDrawer']),
      onResponsiveInverted () {
      }
    }
  }
</script>

<style lang="scss">
#app-drawer {
  .v-list__tile {
    border-radius: 4px;

    &--buy {
      margin-top: auto;
      margin-bottom: 17px;
    }
  }

  .v-image__image--contain {
    top: 9px;
    height: 60%;
  }

  .search-input {
    margin-bottom: 30px !important;
    padding-left: 15px;
    padding-right: 15px;
  }
}
</style>

<style>
.tamanho-40 {
     height: 40px !important;
     margin-left: 10px;
}
</style>