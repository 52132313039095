var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"fill-height py-0",class:{ 'px-0' : _vm.$vuetify.breakpoint.smAndDown},attrs:{"fluid":""}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{staticClass:"py-0",class:{ 'px-0' : _vm.$vuetify.breakpoint.smAndDown},attrs:{"md":"12"}},[_c('material-card',{staticClass:"pt-0 pb-0 ",attrs:{"id":"materialCardTeste","color":"green"}},[_vm._t("headerCard",[_c('v-col',{staticClass:"pt-0 pb-0 ",attrs:{"slot":"header","id":"materialCardHeader"},slot:"header"},[_c('h4',{staticClass:"pt-0 pb-0 font-weight-light mb-0 mt-0 "},[_vm._t("antesTituloPesquisa"),_vm._t("tituloPesquisa",[_vm._v(" "+_vm._s(_vm.tituloPesquisa)+" ")]),_vm._t("depoisTituloPesquisa"),_vm._t("botoesAcaoTitulo",[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g({staticClass:"v-btn--simple",attrs:{"icon":""},on:{"click":_vm.novo}},on),[_c('v-icon',[_vm._v(_vm._s(_vm.iconNewButton))])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.labelNewButton || _vm.$t('padrao_novo')))])])]),_vm._t("botaoDepoisAcaoTitulo")],2)])],{"slot":"header"}),_vm._t("antesDaDataTable"),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"options":_vm.paginacao,"server-items-length":_vm.totalRegistros,"loading":_vm.loading,"hide-default-header":_vm.hideHeaders,"hide-default-footer":_vm.hideActions,"footer-props":{
            prevIcon:'mdi-menu-left color-black',
            nextIcon:'mdi-menu-right color-black'
          }},on:{"update:options":function($event){_vm.paginacao=$event}},scopedSlots:_vm._u([{key:"headerCell",fn:function(ref){
          var header = ref.header;
return [_c('span',{staticClass:"subheading font-weight-light text-success text--darken-3",domProps:{"textContent":_vm._s(header.text)}})]}},{key:"item",fn:function(item){return _vm._t("items",null,{"item":item})}}],null,true)},[_c('template',{slot:"no-data"},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.novo()}}},[_c('v-icon',[_vm._v(_vm._s(_vm.iconNewButton))]),_vm._v(" "+_vm._s(_vm.labelNewButton || _vm.$t('padrao_novo'))+" ")],1)],1),_vm._t("headers",null,{"slot":"header"})],2)],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }