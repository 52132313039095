import Api from '@/services/Api'

export default {
  register (credentials) {
    return Api().post('register', credentials)
  },
  login (credentials) {
    return Api().post('login', credentials)
  },
  redefinirSenha (email) {
    return Api().post('redefinirSenha', email)
  },
  forgotPassword (forgotPassword) {
    return Api().post('forgotPassword', forgotPassword)
  },
  changePassword (changePassword) {
    return Api().post('changePassword', changePassword)
  },
  findByEmail (email) {
    const url = 'auth/byEmail'
    const api = Api()
    const param = { email }
    return api.post(url, param)
  },
  saveUser (usuario) {
    return Api().post('saveUser', usuario)
  },
  validateToken (userdata) {
    return Api().post('validateToken', userdata)
  },
  signin (userdata) {
    return Api().post('signin', userdata)
  }
}

/*
AuthenticationService.register({
    email: 'testing@gmail.com',
    password: '123456'
})
*/
