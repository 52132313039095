<template>
  <v-autocomplete
    v-if="!toAdd"
    :value="value"
    :items="tiposServicosDisponiveis[idEmpresaCliente]"
    :loading="autoCompleteLoading"
    :search-input.sync="autoCompleteCampoBusca"
    :filter="filterAc"
    :label="$t('TipoServicoFornecedorAutoComplete.tituloPagina')"
    hide-no-data
    item-text="nome"
    item-value="id"
    :placeholder="$t('padrao_digite_para_buscar')"
    prepend-icon="mdi-database-search"
    append-outer-icon="mdi-plus"
    return-object
    :clearable="clearable"
    :readonly="readonly"
    :multiple="multiple"
    chips
    @input="disparaEvento($event)"
    @click:clear="disparaEventoLimpaCampo($event)"
    @click:append-outer="toAdd = !toAdd"
  />
  <v-text-field
    v-else
    v-model="tipoServicoAdicionar"
    prepend-icon="mdi-close"
    append-icon="mdi-content-save"
    :readonly="readonly"
    @click:prepend="toAdd = !toAdd"
    @click:append="saveNewTipoServico()"
  />
</template>

<script>

  import TipoServicoFornecedor from '../services/TipoServicoFornecedorService'
  import { mapState } from 'vuex'

  export default {
    name: 'TipoServicoFornecedorAutoComplete',
    props: {
      value: {
        type: Array,
        default: () => [],
        require: false
      },
      readonly: {
        type: Boolean,
        default: false,
        require: false
      },
      multiple: {
        type: Boolean,
        default: true,
        require: false
      },
      clearable: {
        type: Boolean,
        default: true,
        require: false
      },
      idEmpresaCliente: {
        type: Number,
        default: 0,
        require: true
      }
    },
    data () {
      return {
        // autoCompleteItens: [],
        autoCompleteLoading: false,
        autoCompleteCampoBusca: '',
        toAdd: false,
        tipoServicoAdicionar: '',
        loadingInput: false
      }
    },    
    computed: {
      ...mapState('tipoServicoFornecedor',['tiposServicosDisponiveis']),
    },
    watch: {
      idEmpresaCliente(val){
        if (!val){
          return;
        }
        if(!this.tiposServicosDisponiveis[val]){
          this.autoCompleteLoading = true

          TipoServicoFornecedor.findAll(val)
            .then(listagem => {
              if(listagem.data){
                this.$store.commit('tipoServicoFornecedor/setTiposServicosDisponiveis', {idEmpresaCliente: this.idEmpresaCliente, items: listagem.data})
              }
            })
            .catch(error => {
              console.log('Deu ruim aqui -> ', error)
            })
            .finally(() => {
              this.autoCompleteLoading = false
            })
        }
      }
    },
    methods: {
      disparaEventoLimpaCampo (event) {
        this.value.length = 0
        this.$emit('click:clear', event)
      },
      disparaEvento (event) {
        this.$emit('input', event)
      },
      filterAc (item, queryText, itemText) {
        return true
      },
      saveNewTipoServico() {
        const novoRegistro = {
          nome: this.tipoServicoAdicionar,
          idEmpresaCliente: this.idEmpresaCliente
        }

        this.loadingInput = true

        TipoServicoFornecedor.insert(novoRegistro)
          .then(registro => {
            const items = [...this.tiposServicosDisponiveis[this.idEmpresaCliente], registro.data]
            this.value.push(registro.data)
            this.$store.commit('tipoServicoFornecedor/setTiposServicosDisponiveis', {idEmpresaCliente: this.idEmpresaCliente, items})
            this.loadingInput = false
            this.toAdd = !this.toAdd
          })
          .catch((error) => {
            console.log('deu ruim -> ', error)
            this.loadingInput = false
          })
      }
    }
  }
</script>
