import { set, toggle } from '@/utils/vuex'

export default {
  setDrawer: set('drawer'),
  setLoading: set('loading'),
  setImage: set('image'),
  setColor: set('color'),
  toggleDrawer: toggle('drawer'),
  setUsuarioLogado: set('usuarioLogado')
}
