<template>
  <v-container
    class="fill-height py-0"
    :fluid="fluid"
    :class="tamanhoForm"
  >
    <v-row
      justify="center"
    >
      <v-col :class="[tamanhoForm, {'px-0' : $vuetify.breakpoint.smAndDown}]">
        <material-card
          id="materialCardTeste"
          color="green"
        >
          <v-col
            v-if="!tabsCabecalhoView"
            slot="header"
            cols="12"
            :class="{'mx-0' : $vuetify.breakpoint.smAndDown}"
          >
            <v-btn
              v-if="mostraBotaoFechar"
              class="v-btn--simple mt-1"
              icon
              @click="closeDialog()"
            >
              <v-icon>mdi-door-open</v-icon>
            </v-btn>
            <span
              class="subheading font-weight-bold mr-3"
              style="align-self: center"
            >{{ tituloPagina }}
            </span>
          </v-col>

          <v-col
            v-if="tabsCabecalhoView"
            id="materialCardHeader"
            slot="header"
          >
            <v-tabs
              v-model="tabSelecionada"
              slider-color="yellow"
              show-arrows
              :grow="$vuetify.breakpoint.smAndUp"
              background-color="primary"
              color="white"
            >
              <v-btn
                v-if="mostraBotaoFechar"
                class="v-btn--simple mt-1"
                icon
                @click="closeDialog()"
              >
                <v-icon>mdi-door-open</v-icon>
              </v-btn>
              <span
                class="subheading font-weight-bold mr-3"
                style="align-self: center; "
              >
                {{ tituloPaginaTab }}
              </span>
              <v-tab
                v-for="item in tabsCabecalhoView"
                :key="item.link"
                :href="item.link"
                class="mr-3"
              >
                <span>
                  {{ item.texto }}
                </span>
                <v-icon
                  v-if="item.icone"
                  class="mr-2"
                >
                  {{ item.icone }}
                </v-icon>
              </v-tab>
              <v-menu
                v-if="tabsCabecalhoView && $vuetify.breakpoint.smAndDown && tabsCabecalho && tabsCabecalho.length > 1"
                bottom
                allow-overflow
                class="v-tabs__div"
                left
                light
              >
                <template v-slot:activator="{ on }">
                  <a
                    class="v-tabs__item mt-3"
                    style="color:white;"
                    v-on="on"
                  >
                    <!--
                    {{ $vuetify.lang.t('$vuetify.padrao_mais_informacoes') }}
                    -->
                    <v-icon>mdi-account-box-multiple</v-icon>
                  </a>
                </template>
                <v-list class="grey lighten-3">
                  <v-list-item
                    v-for="item in tabsCabecalhoMenu"
                    :key="item.link"
                    @click="addItem(item)"
                  >
                    <v-icon v-if="item.icone">
                      {{ item.icone }}
                    </v-icon>
                    {{ item.texto }}
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-btn
                v-if="mostrarBotaoInfo"
                class="v-btn--simple mt-1"
                icon
                @click="mostrarInfo()"
              >
                <v-icon>mdi-information-outline</v-icon>
              </v-btn>
            </v-tabs>
          </v-col>
          <v-form>
            <crud-filho-form
              :mostra-formulario="true"
              :is-editting="isEditting"
              :mensagem-pergunta-remocao="mensagemPerguntaRemocao"
              :alerta-validacao="alertValidacao"
              :mensagem-erro="mensagemErro"
              :mostra-botao-salvar-continuar="mostraBotaoSalvarContinuar"
              :forca-mostra-botao-salvar-continuar="forcaMostraBotaoSalvarContinuar"
              :mostra-botao-deletar="mostraBotaoDeletar"
              @closeDialogEvent="closeDialog"
              @saveEvent="saveEvent"
              @save-continue-event="saveContinueEvent"
              @deleteEvent="remover"
            >
              <slot
                slot="default"
                name="default"
              />
              <slot
                slot="botoes"
                name="botoes"
              />
              <slot
                slot="modificadoPor"
                name="modificadoPor"
              />
              <slot
                slot="botoesAdicionaisAntesCancelar"
                name="botoesAdicionaisAntesCancelar"
              />
              <slot
                slot="botoesAdicionaisAntesSalvar"
                name="botoesAdicionaisAntesSalvar"
              />
              <slot
                slot="botoesAdicionaisAntesSalvarContinuar"
                name="botoesAdicionaisAntesSalvarContinuar"
              />
              <slot
                slot="botoesAdicionaisTelaPequenaAntesSalvar"
                name="botoesAdicionaisTelaPequenaAntesSalvar"
              />
              <slot
                slot="botoesAdicionaisTelaPequenaDepoisCancelar"
                name="botoesAdicionaisTelaPequenaDepoisCancelar"
              />
              <slot
                slot="botoesAdicionaisTelaPequenaAntesDeletar"
                name="botoesAdicionaisTelaPequenaAntesDeletar"
              />
              <slot
                slot="botoesAdicionaisTelaPequenaDepoisDeletar"
                name="botoesAdicionaisTelaPequenaDepoisDeletar"
              />
            </crud-filho-form>
          </v-form>
        </material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

  export default {
    props: {
      tituloPaginaTab: {
        type: String,
        default: null
      },
      tituloPagina: {
        type: String,
        default: null
      },
      tabsCabecalho: {
        type: Array,
        default: null
      },
      isEditting: {
        type: Boolean,
        default: true
      },
      mensagemPerguntaRemocao: {
        type: String,
        default: null
      },
      alertValidacao: {
        type: Boolean,
        default: false
      },
      mensagemErro: {
        type: String,
        default: null
      },
      tamanhoForm: {
        type: String,
        default: 'xs12'
      },
      mostraBotaoFechar: {
        type: Boolean,
        default: true
      },
      mostraBotaoSalvarContinuar: {
        type: Boolean,
        default: true
      },
      forcaMostraBotaoSalvarContinuar: {
        type: Boolean,
        default: false
      },
      mostrarBotaoInfo: {
        type: Boolean,
        default: false
      },
      mostraBotaoDeletar: {
        type: Boolean,
        default: true
      },
      fluid: {
        type: Boolean,
        default: true
      }
    },
    data: () => ({
      tab: null
    }),
    computed: {
      tabSelecionada: {
        get () {
          return this.tab
        },
        set (tabSelecionada) {
          if (this.tab !== tabSelecionada) {
            this.tab = tabSelecionada
            this.$emit('update:tab-selecionada', tabSelecionada)
            this.$emit('update:tabSelecionada', tabSelecionada)
          }
        }
      },
      tabsCabecalhoView () {
        if (!this.tabsCabecalho) {
          return null
        }
        if (!this.$vuetify.breakpoint.smAndDown) {
          return this.tabsCabecalho
        }

        if (!this.tab || this.tab == null) {
          return [this.tabsCabecalho[0]]
        }

        let tabsMenuRetorno = this.tabsCabecalho.filter((menu) => {
          return menu.link.substring(1) === this.tab
        })
        return tabsMenuRetorno
      },
      tabsCabecalhoMenu () {
        if (!this.tabsCabecalho) {
          return []
        }
        let tabsMenuRetorno = this.tabsCabecalho.filter((menu) => {
          return menu.link.substring(1) !== this.tab
        })
        return tabsMenuRetorno
      }
    },
    methods: {
      remover () {
        this.$emit('deleteEvent')
      },
      addItem (item) {
        this.tabSelecionada = item.link.substring(1)
      },
      closeDialog () {
        this.$emit('closeDialogEvent')
      },
      saveEvent () {
        this.$emit('saveEvent')
      },
      saveContinueEvent () {
        this.$emit('save-continue-event')
      },
      mostrarInfo () {
        this.$emit('mostrarInfo')
      }
    }
  }
</script>
<style>
.bold {
  font-weight: bold;
}
</style>
