/**
 * Define all of your application routes here
 * for more information on routes, see the
 * official documentation https://router.vuejs.org/en/
 */
export default [
  {
    path: '/user-profile',
    name: 'User Profile',
    view: 'UserProfile'
  },
  {
    path: '/table-list',
    name: 'Table List',
    view: 'TableList'
  },
  {
    path: '/pessoas',
    name: 'Pessoas',
    view: 'PessoasList'
  },
  {
    path: '/pessoaForm',
    name: 'Cadastro de Pessoa',
    view: 'PessoasForm'
  },
  {
    path: '/pessoaForm/:id',
    name: 'Formulário do Pessoa',
    view: 'PessoasForm'
  },
  {
    path: '/condominios',
    name: 'Condomínios',
    view: 'Condominios'
  },
  {
    path: '/condominioForm',
    name: 'Cadastro de Condomínio',
    view: 'CondominioForm'
  },
  {
    path: '/condominioForm/:id',
    name: 'Formulário do Condomínio',
    view: 'CondominioForm'
  },
  {
    path: '/tipoEventos',
    name: 'Tipos de Evento',
    view: 'TipoEventoList'
  },
  {
    path: '/caixas',
    name: 'Caixas',
    view: 'CaixaList'
  },
  {
    path: '/statusEventos',
    name: 'Status de Evento',
    view: 'StatusEventoList'
  },
  {
    path: '/fornecedores',
    name: 'Fornecedores',
    view: 'FornecedorList'
  },
  {
    path: '/despesas',
    name: 'Despesas',
    view: 'DespesaList'
  },
  {
    path: '/despesasForm',
    name: 'Cadastro de Despesa',
    view: 'DespesaForm'
  },
  {
    path: '/despesasForm/:id',
    name: 'Formulário de Despesa',
    view: 'DespesaForm'
  },
  {
    path: '/depositos',
    name: 'Depositos',
    view: 'DepositoList'
  },
  {
    path: '/estoque',
    name: 'Estoque',
    view: 'EstoqueList'
  },
  {
    path: '/histmovcai',
    name: 'Histórico de Movimentação de Caixa',
    view: 'HistoricoMovimentacaoCaixaList'
  },
  {
    path: '/histmovest',
    name: 'Histórico de Movimentação de Estoque',
    view: 'HistoricoMovimentacaoEstoqueList'
  },
  {
    path: '/histmovest/:idDeposito/:idItem',
    name: 'Histórico de Movimentação de Estoque De Item/Deposito',
    view: 'HistoricoMovimentacaoEstoqueList'
  },
  {
    path: '/histmovestForm/:backUrl',
    name: 'Movimentação de Estoque',
    view: 'HistoricoMovimentacaoEstoqueForm'
  },
  {
    path: '/histmovestForm/:backUrl/:id',
    name: 'Ver Movimentação de Estoque',
    view: 'HistoricoMovimentacaoEstoqueForm'
  },
  {
    path: '/histmovcaiForm/:backUrl',
    name: 'Movimentação de Caixa',
    view: 'HistoricoMovimentacaoCaixaForm'
  },
  {
    path: '/histmovcaiForm/:backUrl/:id',
    name: 'Ver Movimentação de Caixa',
    view: 'HistoricoMovimentacaoCaixaForm'
  },
  {
    path: '/depositoForm',
    name: 'Cadastro de Deposito',
    view: 'DepositoForm'
  },
  {
    path: '/depositoForm/:id',
    name: 'Formulário de Deposito',
    view: 'DepositoForm'
  },
  {
    path: '/fornecedorForm',
    name: 'Cadastro de Fornecedor',
    view: 'FornecedorForm'
  },
  {
    path: '/fornecedorForm/:id',
    name: 'Formulário de Fornecedor',
    view: 'FornecedorForm'
  },
  {
    path: '/tipoEventoForm',
    name: 'Cadastro de Tipo de Evento',
    view: 'TipoEventoForm'
  },
  {
    path: '/tipoEventoForm/:id',
    name: 'Formulário de Tipo de Evento',
    view: 'TipoEventoForm'
  },
  {
    path: '/caixaForm',
    name: 'Cadastro de Caixa',
    view: 'CaixaForm'
  },
  {
    path: '/caixaForm/:id',
    name: 'Formulário de Caixa',
    view: 'CaixaForm'
  },
  {
    path: '/formaPagamentoForm',
    name: 'Cadastro de Forma de Pagamento',
    view: 'FormaPagamentoForm'
  },
  {
    path: '/formaPagamentoForm/:id',
    name: 'Formulário de Forma de Pagamento',
    view: 'FormaPagamentoForm'
  },
  {
    path: '/formasPagamento',
    name: 'Listagem de Forma de Pagamento',
    view: 'FormaPagamentoList'
  },
  {
    path: '/statusEventoForm',
    name: 'Cadastro de Status de Evento',
    view: 'StatusEventoForm'
  },
  {
    path: '/statusEventoForm/:id',
    name: 'Formulário de Status de Evento',
    view: 'StatusEventoForm'
  },
  {
    path: '/itens',
    name: 'Item',
    view: 'ItemList'
  },
  {
    path: '/itemForm',
    name: 'Cadastro de Item',
    view: 'ItemForm'
  },
  {
    path: '/itemForm/:id',
    name: 'Formulário de Item',
    view: 'ItemForm'
  },
  {
    path: '/users',
    name: 'Usuário',
    view: 'UserList'
  },
  {
    path: '/userForm',
    name: 'Cadastro de Usuário',
    view: 'UserForm'
  },
  {
    path: '/userForm/:id',
    name: 'Formulário de Usuario',
    view: 'UserForm'
  },
  {
    path: '/clientes',
    name: 'Cliente',
    view: 'ClientesList'
  },
  {
    path: '/clienteForm',
    name: 'Cadastro de Cliente',
    view: 'ClientesForm'
  },
  {
    path: '/clienteForm/:id',
    name: 'Formulário de Cliente',
    view: 'ClientesForm'
  },
  {
    path: '/eventos',
    name: 'Evento',
    view: 'EventosList'
  },
  {
    path: '/eventoForm',
    name: 'Cadastro de Evento',
    view: 'EventosForm'
  },
  {
    path: '/eventoForm/:id',
    name: 'Formulário de Evento',
    view: 'EventosForm'
  },
  {
    path: '/textos-contrato',
    name: 'Textos de contrato',
    view: 'TextosContratoList'
  },
  {
    path: '/textos-contrato/new',
    name: 'Criação de texto de contrato',
    view: 'TextosContratoForm'
  },
  {
    path: '/textos-contrato/:id',
    name: 'Edição de texto de contrato',
    view: 'TextosContratoForm'
  },
  {
    path: '/contrato',
    name: 'Contratos',
    view: 'ContratoList',
  },
  {
    path: '/contrato/new',
    name: 'Criação de contrato',
    view: 'ContratoForm',
  },
  {
    path: '/contrato/:id',
    name: 'Edição de contrato',
    view: 'ContratoForm'
  },
  {
    path: '/typography',
    view: 'Typography'
  },
  {
    path: '/empresaClientes',
    name: 'Empresas Clientes',
    view: 'EmpresaClientesList'
  },
  {
    path: '/empresaClientesForm',
    name: 'Cadastro de Empresa Cliente',
    view: 'EmpresaClientesForm'
  },
  {
    path: '/empresaClientesForm/:id',
    name: 'Formulário de Empresa Cliente',
    view: 'EmpresaClientesForm'
  },
  {
    path: '/reclamacoes',
    name: 'Reclamações',
    view: 'ReclamacoesList'
  },
  {
    path: '/reclamacoesForm',
    name: 'Cadastro de Reclamação',
    view: 'ReclamacoesForm'
  },
  {
    path: '/reclamacoesForm/:id',
    name: 'Formulário de Reclamação',
    view: 'ReclamacoesForm'
  },
  {
    path: '/icons',
    view: 'Icons'
  },
  {
    name: 'Login',
    path: '/login',
    view: 'LoginForm'
  },
  {
    name: 'Altera Senha',
    path: '/resetPassword',
    view: 'LoginForm'
  },
  {
    name: 'Registro',
    path: '/register',
    view: 'RegisterForm'
  },
  {
    path: '/maps',
    view: 'Maps'
  },
  {
    path: '/notifications',
    view: 'Notifications'
  },
  {
    path: '/upgrade',
    name: 'Upgrade to PRO',
    view: 'Upgrade'
  },

  {
    path: '*',
    // Relative to /src/views
    view: 'Dashboard'
  }
]
