import Vue from 'vue'
import App from './App.vue'
import router from './router'
import i18n from './i18n'
import store from './store'
import Vuetify from './plugins/vuetify'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'
import DateUtils from './utils/DateUtils'
import CKEditor from '@ckeditor/ckeditor5-vue2';

// Components
import './components'

// Plugins
import './plugins'

// Sync router with store
// import { sync } from 'vuex-router-sync'

// sync(store, router)

Vue.config.productionTip = false

Vue.directive('date', {
  inserted (elemento) {
    let data = elemento.innerHTML
    try {
      data = DateUtils.formatDate(data)
    } catch (error) {
      data = ''
    }
    elemento.innerHTML = data
  }
})

Vue.use(CKEditor)

new Vue({
  vuetify: Vuetify,
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
