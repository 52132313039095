<template>
  <v-col>
    <v-row>
      <v-select
        v-model="idTextoSelecionado"
        :label="$t('TextosContratoForm.text')"
        :items="textosContrato"
        item-value="id"
        item-text="summary"
        prepend-icon="mdi-format-list-bulleted-type"
      />
      <v-btn color="primary" icon large @click.prevent="addTexto">
        <v-icon dard>
          mdi-plus-circle
        </v-icon>
      </v-btn>
    </v-row>
    <v-row>
      <v-col>
        <v-row v-for="(textoId, index) of value" :key="index">
          <span>{{ getTexto(textoId).summary || "Texto não encontrado" }}</span>
          <v-spacer></v-spacer>
          <v-btn @click="removeTextoAt(index)" icon color="error" small>
            <v-icon> mdi-trash-can-outline</v-icon>
          </v-btn>
        </v-row>
      </v-col>
    </v-row>
  </v-col>
</template>
<script>
import TextoContratoService from "@/services/TextosContratoService";

export default {
  name: "TextoContratoSelector",
  props: {
    value: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    textosContrato: [],
    idTextoSelecionado: null
  }),
  created() {
    this.preencheTextosContrato();
  },
  methods: {
    addTexto() {
      if (!this.idTextoSelecionado) {
        return;
      }
      this.$emit("input", [...this.value, this.idTextoSelecionado]);
      this.idTextoSelecionado = null;
    },
    getTexto(idTexto) {
      if (!this.textosContrato) {
        return {};
      }
      return (
        this.textosContrato.find(
          textoContrato => textoContrato.id === idTexto
        ) || {}
      );
    },
    removeTextoAt(index) {
      this.value.splice(index, 1);
      this.$emit("input", [...this.value]);
    },
    preencheTextosContrato() {
      return TextoContratoService.findAll()
        .then(textosContrato => {
          if (
            textosContrato &&
            textosContrato.data &&
            textosContrato.data.rows
          ) {
            this.textosContrato = textosContrato.data.rows;
          }
        })
        .catch(error => {
          console.error(error);
          this.$root.$emit("mostra_dialogo_erro", {
            dialogoDescricao: "Não foi possível carregar os textos de contrato"
          });
        });
    }
  }
};
</script>
