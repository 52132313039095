<template>
  <v-container
    class="fill-height py-0"
    fluid
    :class="{ 'px-0' : $vuetify.breakpoint.smAndDown}"
  >
    <v-row
      justify="center"
    >
      <v-col
        class="py-0"
        md="12"
        :class="{ 'px-0' : $vuetify.breakpoint.smAndDown}"
      >
        <material-card
          id="materialCardTeste"
          color="green"
          class="pt-0 pb-0 "
        >
          <slot
            slot="header"
            name="headerCard"
          >
            <v-col
              id="materialCardHeader"
              slot="header"
              class="pt-0 pb-0 "
            >
              <h4 class="pt-0 pb-0 font-weight-light mb-0 mt-0 ">
                <slot name="antesTituloPesquisa" />
                <slot name="tituloPesquisa">
                  {{ tituloPesquisa }}
                </slot>
                <slot name="depoisTituloPesquisa" />
                <slot name="botoesAcaoTitulo">
                  <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="v-btn--simple"
                        icon
                        @click="novo"
                        v-on="on"
                      >
                        <v-icon>{{ iconNewButton }}</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ labelNewButton || $t('padrao_novo') }}</span>
                  </v-tooltip>
                </slot>
                <slot name="botaoDepoisAcaoTitulo" />
              </h4>
            </v-col>
          </slot>
          <slot name="antesDaDataTable" />

          <v-data-table
            :headers="headers"
            :items="items"
            :options.sync="paginacao"
            :server-items-length="totalRegistros"
            :loading="loading"
            :hide-default-header="hideHeaders"
            :hide-default-footer="hideActions"
            class="elevation-1"
            :footer-props="{
              prevIcon:'mdi-menu-left color-black',
              nextIcon:'mdi-menu-right color-black'
            }"
          >
            <template slot="no-data">
              <v-btn
                color="primary"
                @click.stop="novo()"
              >
                <v-icon>{{ iconNewButton }}</v-icon>
                {{ labelNewButton || $t('padrao_novo') }}
              </v-btn>
            </template>
            <template
              slot="headerCell"
              slot-scope="{ header }"
            >
              <span
                class="subheading font-weight-light text-success text--darken-3"
                v-text="header.text"
              />
            </template>
            <slot
              slot="header"
              name="headers"
            />
            <slot
              slot="item"
              slot-scope="item"
              :item="item"
              name="items"
            />
          </v-data-table>
        </material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

  export default {
    props: {
      tituloPesquisa: {
        type: String,
        default: 'Pesquisa'
      },
      headers: {
        type: Array,
        default: null
      },
      totalRegistros: {
        type: Number,
        default: 0
      },
      items: {
        type: Array,
        default: null
      },
      loading: {
        type: Boolean,
        default: false
      },
      hideHeaders: {
        type: Boolean,
        default: false
      },
      hideActions: {
        type: Boolean,
        default: false
      },
      pagination: {
        type: Object,
        default: () => {}
      },
      labelNewButton: {
        type: String,
        default: null
      },
      iconNewButton: {
        type: String,
        default: 'mdi-plus'
      }
    },
    data: () => ({
      itemRemover: {},
      // pagination: {}
    }),
    computed: {
      paginacao: {
        get () {
          return this.pagination
        },
        set (paginacao) {
          if (this.pagination !== paginacao) {
            // this.pagination = paginacao
            this.$emit('update:pagination', paginacao)
            this.$emit('update:paginacao', paginacao)
            this.buscar()
          }
        }
      }
    },
    methods: {
      buscar () {
        this.$emit('buscar-event', {
          pagination: this.pagination
        })
      },
      novo () {
        this.$emit('novo-event')
      }
    }
  }
</script>
<style>
#materialCardTeste .v-offset,
#materialCardTeste .v-offset .v-card--material__header {
  padding: 0px;
}

.color-black {
  color: black !important;
}

</style>
