<template>
  <v-row>
    <v-col cols="12">
      <v-text-field
        v-model="objEditar.cep"
        mask="#####-###"
        :label="$t('EnderecoForm.cep')"
      >
        <v-btn
          slot="append"
          :loading="loadingEndereco"
          :disabled="loadingEndereco"
          color="primary"
          @click="buscaEndereco"
        >
          <v-icon>mdi mdi-map-search</v-icon>
          <span class="hidden-sm-and-down">
            {{ $t('EnderecoForm.buscarEndereco') }}
          </span>
          <div
            slot="loader"
            style="display:flex;"
          >
            <span class="custom-loader">
              <v-icon light>mdi-loading</v-icon>
            </span>
            <span class="hidden-sm-and-down">Buscando</span>
          </div>
        </v-btn>
      </v-text-field>
    </v-col>
    <v-col cols="12">
      <v-autocomplete
        v-model="autoCompleteModel"
        :items="autoCompleteItens"
        :loading="autoCompleteLoading"
        :search-input.sync="autoCompleteCampoBusca"
        :filter="filterAc"
        color="white"
        hide-no-data
        hide-selected
        item-text="cidadeEstado"
        item-value="idCidade"
        label="Estado / Cidade"
        :placeholder="$t('padrao_digite_para_buscar')"
        prepend-icon="mdi-database-search"
        return-object
      />
    </v-col>
    <v-col
      cols="12"
      md="8"
    >
      <v-text-field
        v-model="objEditar.rua"
        prepend-icon="mdi mdi-apple-icloud"
        :label="$t('EnderecoForm.rua')"
      />
    </v-col>
    <v-col
      cols="12"
      md="4"
    >
      <v-text-field
        v-model="objEditar.numero"
        :label="$t('EnderecoForm.numero')"
        mask="#####"
      />
    </v-col>

    <v-col
      cols="12"
      md="6"
    >
      <v-text-field
        v-model="objEditar.complemento"
        :label="$t('EnderecoForm.complemento')"
      />
    </v-col>

    <v-col
      cols="12"
      md="6"
    >
      <v-text-field
        v-model="objEditar.bairro"
        :label="$t('EnderecoForm.bairro')"
      />
    </v-col>
  </v-row>
</template>
<script>


  import RegionalService from '@/services/RegionalService'

  export default {
    props: {
      objEditar: {
        type: Object,
        default: () => {}
      }
    },
    data() {
      return {
        autoCompleteModel: null,
        autoCompleteItens: [],
        autoCompleteLoading: false,
        autoCompleteCampoBusca: '',
        loadingEndereco: false,
      }
    },
    watch: {
      objEditar(val){
        if(val && val.Cidade){
          this.autoCompleteModel = val.Cidade
          this.autoCompleteItens = [val.Cidade]
        }
      },
      autoCompleteModel(val){
        if(val != null){
          this.objEditar.idCidade = val.id
        }else{
          this.objEditar.idCidade = null
        }
      }
    },
    methods: {
      buscaEndereco () {
        this.loadingEndereco = true
        this.alertValidacao = false
        this.error = 'null'
        let cep = this.objEditar.cep
        if (!cep || cep.length < 8) {
          this.alertValidacao = true
          this.error = 'Campo cep não preenchido corretamente'
          this.loadingEndereco = false
          return
        }
        cep = cep.replace('-', '')
        if (cep.length !== 8) {
          this.alertValidacao = true
          this.error = 'Campo cep não preenchido corretamente'
          this.loadingEndereco = false
          return
        }
        RegionalService.buscaEnderecoPorCEP(cep)
          .then(resultado => {
            if (!resultado || resultado.data.erro) {
              this.alertValidacao = true
              this.error = 'Não foi encontrado algum endereço para este CEP'
              this.objEditar.cidade = ''
              this.objEditar.rua = ''
              this.objEditar.bairro = ''
              return
            }

            this.objEditar.rua = resultado.data.logradouro
            this.objEditar.bairro = resultado.data.bairro

            const estadoCidade = resultado.data.localidade + ' ' + resultado.data.uf

            RegionalService.buscaCidadesPorParam(estadoCidade)
              .then(cidades => {
                if (cidades && cidades.data && cidades.data.length === 1) {
                  this.autoCompleteItens = cidades.data
                  this.autoCompleteModel = cidades.data[0]
                }
              })
          })
          .catch(error => {
            this.$root.$emit('mostra_dialogo_erro', {
              dialogoDescricao: '' + error
            })
          })
          .finally(() => {
            this.loadingEndereco = false
          })
      },
      filterAc (item, queryText, itemText) {
        return true
      },
      buscaCidades (param) {
        this.autoCompleteLoading = true
        RegionalService.buscaCidadesPorParam(param)
          .then(cidades => {
            this.autoCompleteItens = cidades.data
          })
          .catch(error => {
            this.$root.$emit('mostra_dialogo_erro', {
              dialogoDescricao: 'Erro na busca das cidades ' + error
            })
          })
          .finally(() => {
            this.autoCompleteLoading = false
          })
      },
    }
  }
</script>