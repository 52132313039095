<template>
  <v-avatar
    :tile="true"
    :size="size"
  >
    <v-img
      v-if="imagemAtual"
      :src="imagemAtual"
      width="100%"
      max-height="100%"
      max-width="100%"
      height="100%"
    >
      <div id="bts">
        <v-btn
          v-if="mostraBotaoAnterior"
          class="v-btn--simple btn_retornar"
          icon
          @click="retornaImagem"
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <v-btn
          v-if="mostraBotaoVerImagem"
          class="v-btn--simple btn_visualizar"
          icon
          @click="visualizaImagem"
        >
          <v-icon>mdi-fullscreen</v-icon>
        </v-btn>
        <v-btn
          v-if="mostraBotaoProximo"
          class="v-btn--simple btn_avancar"
          icon
          @click="avancaImagem"
        >
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </div>
    </v-img>
  </v-avatar>
</template>
<script>

  import ImageService from '../../services/ImagemService'

  export default {
    props: {
      imagensStr: {
        type: String,
        default: null
      },
      size: {
        type: String, 
        default: '128px'
      },
      mostraBotoes: {
        type: Boolean,
        default: true
      }
    },
    data() {
      return {
        imagens: null,
        indice: 0
      }
    },
    computed:{
      imagemAtual () {
        if(!this.imagens || this.imagens.length === 0){
          return null
        }
        return this.imagens[this.indice].img
      },
      mostraBotaoAnterior() {
        if(!this.mostraBotoes || !this.imagens || this.imagens.length === 0){
          return false
        }

        return this.indice > 0
      },
      mostraBotaoProximo() {
        if(!this.mostraBotoes || !this.imagens || this.imagens.length === 0){
          return false
        }

        return this.indice < (this.imagens.length - 1)
      },
      mostraBotaoVerImagem() {
        const condition = this.mostraBotoes && this.imagemAtual != null
        console.log(condition)
        return condition
      }
    },
    watch:{
      imagensStr(){
        this.processaListaStr()
      }
    },
    created(){
      this.processaListaStr()
    },
    methods: {
      visualizaImagem () {
        this.$root.$emit('view-image', this.imagemAtual)
      },
      avancaImagem() {
        this.indice ++
      },
      retornaImagem() {
        this.indice --
      },
      processaListaStr() {
        if(!this.imagensStr || this.imagensStr.length === 0){
          return
        }

        const imagensListIds = JSON.parse(this.imagensStr)

        this.indice = 0
        this.imagens = []

        ImageService.findByIds({ids: imagensListIds})
          .then(imagensBanco => {
            const imagensRetorno = imagensBanco.data
            this.imagens = []
            for (let i = 0; i < imagensRetorno.length; i++) {
              ImageService.processaImagemBanco(imagensRetorno[i])
              this.imagens.push(imagensRetorno[i])
            }
          })

      },

    }
  }
</script>
<style>
.btn_avancar {
  position: absolute;
  bottom: 0px;
  right: 5px;
}
.btn_visualizar{
    position: absolute;
    bottom: 0;
    left: calc(100%/2 - 18px);
}
.btn_retornar {
  position: absolute;
  bottom: 0px;
  left: 5px;
}
</style>