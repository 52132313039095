export default {
  dataIterator: {
    rowsPerPageText: 'Itens por página:',
    rowsPerPageAll: 'Todos',
    pageText: '{0}-{1} de {2}',
    noResultsText: 'Nenhum registro encontrado',
    nextPage: 'Próxima Página',
    prevPage: 'Página Anterior'
  },
  dataTable: {
    rowsPerPageText: 'Linhas por Página:'
  },
  noDataText: 'Nenhum dado encontrado',
  menu_altera_senha: 'Alterar Senha',
  menu_caixa: 'Caixa',
  menu_clientes: 'Clientes',
  menu_condominios: 'Condomínios',
  menu_depositos: 'Depositos',
  menu_despesa: 'Despesas',
  menu_empresa_clientes: 'Empresas Clientes',
  menu_estoque: 'Estoque',
  menu_eventos: 'Eventos',
  menu_textos_contrato: 'Textos de contrato',
  menu_contrato: 'Contratos',
  menu_formapagamento: 'Forma de Pagamento',
  menu_fornecedores: 'Fornecedores',
  menu_histmovcai: 'Fluxo de Caixa',
  menu_histmovest: 'Movimentação Estoque',
  menu_itens: 'Itens',
  menu_pessoas: 'Pessoas',
  menu_reclamacoes: 'Reclamações',
  menu_status_evento: 'Status de Evento',
  menu_tipos_evento: 'Tipo de Evento',
  menu_users: 'Usuários',
  padrao_exemplo: 'Exemplo',
  padrao_novo: 'Novo',
  padrao_salvar: 'Salvar',
  padrao_salvar_continuar: 'Salvar e Continuar',
  padrao_cancelar: 'Cancelar',
  padrao_delete: 'Deletar',
  padrao_acoes: 'Ações',
  padrao_ativo: 'Ativo',
  padrao_inativo: 'Inativo',
  padrao_atencao: 'Atenção',
  padrao_mais_informacoes: 'Mais...',
  padrao_campo_obrigatorio: 'Campo Obrigatório',
  padrao_carregando: 'Carregando..',
  padrao_esqueceu_sua_senha: 'Esqueceu sua senha?',
  padrao_sucesso: 'Sucesso',
  padrao_deseja_remover_registro: 'Deseja remover este registro?',
  padrao_registro_removido_sucesso: 'Registro removido com sucesso',
  padrao_registro_salvo_sucesso: 'Salvo com sucesso',
  padrao_registro_atualizado_sucesso: 'Atualizado com sucesso',
  padrao_ocorreu_um_erro_ultima_operacao: 'Ocorreu um erro na última operação',
  padrao_ola: 'Olá',
  padrao_registro_nao_encontrado: 'Registro Não encontrado',
  padrao_digite_para_buscar: 'Digite alguma palavra para realizar a busca ... ',
  padrao_pesquisar: 'Pesquisar...',
  padrao_sim: 'Sim',
  padrao_nao: 'Não',
  padrao_erro_na_aplicacao: 'Ocorreu um erro na aplicação',
  padrao_exibir_filtro: 'Exibir Filtro',
  padrao_esconder_filtro: 'Esconder Filtro',
  padrao_selecione: 'Selecione',
  padrao_todos: 'Todos',
  padrao_janeiro: 'Janeiro',
  padrao_fevereiro: 'Fevereiro',
  padrao_marco: 'Março',
  padrao_abril: 'Abril',
  padrao_maio: 'Maio',
  padrao_junho: 'Junho',
  padrao_julho: 'Julho',
  padrao_agosto: 'Agosto',
  padrao_setembro: 'Setembro',
  padrao_outubro: 'Outubro',
  padrao_novembro: 'Novembro',
  padrao_dezembro: 'Dezembro',
  padrao_ano: 'Ano',
  padrao_mes: 'Mês',
  padrao_alerta: 'Alerta',
  padrao_dados_gerais: 'Dados Gerais',
  padrao_imagens: 'Imagens',
  padrao_mostra_imagens: 'Mostra Imagens',
  padrao_esconde_imagens: 'Esconde Imagens',
  padrao_gerar_pdf: 'Gerar PDF',
  padrao_nao_foi_possivel_gerar_pdf: 'Não foi possível gerar o PDF',
  padrao_gerar: 'Gerar',
  padrao_data_criacao: 'Data Criação'
}
