import Api from '@/services/Api'

export default {
  findAll (idEmpresaCliente) {
    const param = {
      idEmpresaCliente
    }
    return Api().post('tipo-servico-fornecedores', param)
  },
  insert (registro) {
    return Api().post('tipo-servico-fornecedor', registro)
  },
  update (registro) {
    return Api().put('tipo-servico-fornecedor', registro)
  },
  delete (registro) {
    return Api().delete(`tipo-servico-fornecedor?id=${registro.id}`, registro)
  },
  findByParam (param, pagination) {
    param = {
      param, pagination
    }
    return Api().post('tipo-servico-fornecedoresParam', param)
  },
  findById (registro) {
    const url = `tipo-servico-fornecedor/${registro.id}`
    return Api().get(url)
  },

}
