<template>
  <v-dialog
    v-model="dialog"
    persistent
    hide-overlay
    fullscreen
  >
    <v-container class="fill-height">
      <div
        class="divLoading tertiary"
        dark
        width="300px"
      >
        <v-card-text style="color:white">
          {{ $vuetify.lang.t('$vuetify.padrao_carregando') }}
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          />
        </v-card-text>
      </div>
    </v-container>
  </v-dialog>
</template>

<script>

  export default {

    computed: {

      dialog () {
        return this.$store.state.app.loading
      }
    }
  }
</script>
<style>
.divLoading {
  min-height: 40px !important;
  max-width: 300px !important;
  min-width: 100px !important;
  max-height: 80px !important;
  margin: auto !important;
  width: 100%;
  height: 100%;
}
</style>
