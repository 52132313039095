<template>
  <v-main>
    <div id="core-view">
      <v-fade-transition mode="out-in">
        <router-view />
      </v-fade-transition>
    </div>
    <core-footer v-if="$route.name !== 'Maps' && $route.name !== 'Login' && $route.name !== 'Altera Senha'" />
  </v-main>
</template>

<script>
  export default {
    metaInfo () {
      return {
        title: 'ControleDeEventos'
      }
    }
  }
</script>

<style>
#core-view {
  padding-bottom: 100px;
}
</style>
