import Api from '@/services/Api'

export default {
  findAll (pagination) {
    return Api().post('textos-contrato/page', pagination)
  },
  insert (textoContrato) {
    return Api().post('textos-contrato', textoContrato)
  },
  update (textoContrato) {
    return Api().put('textos-contrato', textoContrato)
  },
  delete (textoContrato) {
    return Api().delete(`textos-contrato?id=${textoContrato.id}`, textoContrato)
  },
  findById (textoContrato) {
    const url = `textos-contrato/${textoContrato.id}`
    return Api().get(url)
  }
}
